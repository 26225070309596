// Corporate Colors RGBA
// Green Brand
$rgba-green-dark7: rgba(7, 14, 0, 1);
$rgba-green-dark6: rgba(11, 21, 0, 1);
$rgba-green-dark5: rgba(17, 32, 0, 1);
$rgba-green-dark4: rgba(27, 49, 1, 1);
$rgba-green-dark3: rgba(43, 75, 4, 1);
$rgba-green-dark2: rgba(68, 115, 8, 1);
$rgba-green-dark1: rgba(88, 138, 35, 1);
$rgba-green: rgba(110, 177, 24, 1);
$rgba-green-light7: rgba(155, 216, 76, 1);
$rgba-green-light6: rgba(186, 236, 121, 1);
$rgba-green-light5: rgba(206, 246, 156, 1);
$rgba-green-light4: rgba(220, 251, 182, 1);
$rgba-green-light3: rgba(236, 254, 215, 1);
$rgba-green-light2: rgba(245, 255, 233, 1);
$rgba-green-light1: rgba(250, 255, 243, 1);
// Yellow Brand
$rgba-yellow-dark2: rgba(157, 130, 4, 1);
$rgba-yellow-dark1: rgba(181, 159, 53, 1);
$rgba-yellow: rgba(242, 202, 13, 1);
$rgba-yellow-light3: rgba(249, 217, 72, 1);
$rgba-yellow-light2: rgba(249, 226, 119, 1);
$rgba-yellow-light1: rgba(252, 239, 180, 1);
// Black & White
$rgba-black: rgba(43, 45, 48, 1);
$rgba-white: rgba(255, 255, 255, 1);
$rgba-grey-light: rgba(17, 32, 0, 0.1);
// Purple Brand
$rgba-purple: rgba(107, 21, 128, 1);
$rgba-purple-light5: rgba(168, 71, 191, 1);
$rgba-purple-light4: rgba(208, 146, 223, 1);
$rgba-purple-light3: rgba(230, 194, 239, 1);
$rgba-purple-light2: rgba(242, 221, 247, 1);
$rgba-purple-light1: rgba(248, 236, 251, 1);
$rgba-purple-commercial: rgba(235, 206, 241, 1);
$rgba-purple-academic: rgba(244, 229, 247, 1);
$rgba-purple-advanced: rgba(250, 244, 251, 1);
// Red Brand
$rgba-red: rgba(194, 55, 26, 1);
$rgba-red-light2: rgba(224, 104, 79, 1);
$rgba-red-light1: rgba(224, 136, 118, 1);
// Magenta Brand
$rgba-magenta: rgba(165, 22, 83, 1);
$rgba-magenta-light2: rgba(210, 74, 131, 1);
$rgba-magenta-light1: rgba(210, 110, 152, 1);
// Brown Brand
$rgba-brown-dark2: rgba(78, 18, 0, 1);
$rgba-brown-dark1: rgba(90, 38, 23, 1);
$rgba-brown: rgba(121, 28, 1, 1);
$rgba-brown-light3: rgba(188, 80, 48, 1);
$rgba-brown-light2: rgba(188, 108, 85, 1);
$rgba-brown-light1: rgba(204, 153, 153, 1);
// Blue Brand
$rgba-blue-dark1: rgba(8, 39, 105, 1);
$rgba-blue: rgba(24, 68, 162, 1);
$rgba-blue-light4: rgba(75, 118, 209, 1);
$rgba-blue-light3: rgba(120, 156, 232, 1);
$rgba-blue-light2: rgba(155, 184, 243, 1);
$rgba-blue-light1: rgba(199, 216, 249, 1);
// Orange Brand
$rgba-orange: rgba(242, 164, 13, 1);

//Animaciones
@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}


html {
    scroll-behavior: smooth;
}

body {
    font-family: "Nunito", Helvetica, Arial, sans-serif;
}

fieldset {
    border: 0;
    padding-inline-start: 0px;
    padding-block-start: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-block-end: 0px;
    padding-inline-end: 0px;
    margin-top: 10px;

    legend {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 600;
        line-height: 1.15;
        padding-bottom: 6px;
        padding-left: 8px;

        @media screen and (min-width: 855px) {
            font-size: 15px;
        }

        @media screen and (min-width: 1024px) {
            font-size: 18px;
            padding-left: 12px;
        }
    }
}

.crea__loading {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 320px);

    @media screen and (min-width: 1024px) {
        height: calc(100vh - 380px);
    }

    &__simbolo {
        &__svg {
            width: 64px;
            height: auto;

            @media screen and (min-width: 1024px) {
                width: 96px;
                height: auto;
            }

            &__box {
                animation: rotate 2s steps(4) infinite;
                transform-origin: 50% 50%;
            }
        }
    }

    &__txt {
        font-size: 13px;
        transform: translateY(-4px);

        @media screen and (min-width: 1024px) {
            font-size: 15px;
            transform: translateY(-4px);
        }
    }

    &--mini {
        height: 32px;

        @media screen and (min-width: 1024px) {
            height: 48px;
        }

        .crea__loading__simbolo {
            &__svg {
                width: 24px;
                height: auto;

                @media screen and (min-width: 1024px) {
                    width: 40px;
                    height: auto;
                }

                &__box {
                    fill: #c6c6c6;
                    animation: rotate 1.5s steps(4) infinite;
                }
            }
        }
    }
}

#facturacionReact {
    min-height: 60vh;
}

.e {
    &__iframe {
        width: 100%;
        height: 100vh;
    }

    //componentes
    //formulario
    &__form {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__item {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            width: 100%;
            margin-bottom: 16px;

            @media screen and (min-width: 1024px) {
                margin-bottom: 20px;
            }

            inp &--s {
                width: calc(50% - 4px);
            }

            &--link {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0 4px;
                border: 1px solid;

                input:not([type=checkbox]):not([type=radio]):not([type=range]),
                input {
                    border: none;
                    color: $rgba-green-dark1;
                    background: white;
                    font-weight: 600;

                    &::-webkit-input-placeholder,
                    &:focus::-webkit-input-placeholder {
                        color: #000000 !important;
                    }
                }

                .e__btn__ico {
                    font-size: 12px;
                }
            }

            .e__slct {
                width: 100%;

                select {
                    width: 100%;
                }
            }

            &__ico {
                position: absolute;
                top: calc(50% - 2px);
                right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                z-index: 2;

                @media screen and (min-width: 1024px) {
                    width: 28px;
                    height: 28px;
                    top: calc(50% - 4px);
                }

                svg {
                    width: 100%;
                    height: auto;
                    border-radius: 50%;
                    background: white;
                }
            }

            .e__txt__error~.e__form__item__ico {
                top: calc(50% - 12px);

                @media screen and (min-width: 1024px) {
                    top: calc(50% - 15px);
                }
            }

            &.rrr {

                input,
                input:not([type=checkbox]):not([type=radio]):not([type=range]),
                textarea {
                    border: 1px solid red;
                    outline: 0;

                    &:hover,
                    &:focus {
                        box-shadow: inset 0 0 0 1px red;
                    }
                }

                .e__slct select {
                    border: 1px solid red;
                }

                .e__txt__error {
                    display: flex;
                }

                .e__form__item__ico .error {
                    display: flex;
                }
            }
        }

        &__btns {
            margin: 12px 0;
        }
    }

    //select
    &__slct {
        display: flex;
        flex-direction: column;

        label {
            min-width: max-content;
        }

        &--min {
            max-width: 100px;
        }

        &--hd {
            width: 100%;
            max-width: 260px;
            margin: 0 auto;

            @media screen and (min-width: 1024px) {
                max-width: 360px;
            }

            .e__slct__hd {
                display: flex;
                flex-direction: column;
                font-size: 13px;

                @media screen and (min-width: 1024px) {
                    font-size: 15px;
                }
            }
        }

        &.dsbl {
            select {
                color: gray;
                border: 1px solid gray;
                cursor: default;

                option {
                    display: none;
                }

                &:focus {
                    outline: 0;
                }
            }
        }
    }

    //caja email
    &__mail {
        max-width: 460px;
        margin: 0 auto;
        padding: 20px 12px;
        margin-bottom: 96px;

        .e__form__item {
            margin-bottom: 4px;
        }

        .e__btn__scndry {
            margin: 20px auto;
            min-width: 240px;
        }
    }

    //checkbox
    &__chck {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 52px;
        margin: 6px 0;
        border: 0;
        transition: box-shadow .2s ease-out;

        @media screen and (min-width: 1024px) {
            height: 64px;
        }

        label {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 12px;
            cursor: pointer;

            @media screen and (min-width: 1024px) {
                padding: 0 10px 0 15px;
            }
        }

        input {
            margin-right: 12px;
            cursor: pointer;

            &[type="radio"] {
                accent-color: #000000;
            }
        }

        &__ico {
            margin-right: 10px;
        }

        &:hover,
        &:focus {
            transition: box-shadow .2s ease-in;
        }

        //radiobutton
        input[type=radio] {
            --active: $rgba-green;
            --active-inner: #fff;
            --focus: 2px rgba($rgba-green, 0.4%);
            --border: #A847BF;
            --border-hover: $rgba-green;
            --background: #fff;
            --disabled: #F6F8FF;
            --disabled-inner: #E1E6F9;
            -webkit-appearance: none;
            -moz-appearance: none;
            height: 21px;
            outline: none;
            display: inline-block;
            vertical-align: top;
            position: relative;
            box-sizing: inherit;
            margin: 0;
            cursor: pointer;
            border: 1px solid var(--border);
            border-radius: 50%;
            background: var(--b, var(--background));
            transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

            @media screen and (min-width: 1024px) {
                height: 28px;
            }

            &:after {
                content: "";
                display: block;
                left: 0;
                top: 0;
                position: absolute;
                transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
            }

            &:checked {
                --b: var(--active);
                --bc: var(--active);
                --d-o: .3s;
                --d-t: .6s;
                --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
                box-shadow: inset 0 0 0 3px white;

                @media screen and (min-width: 1024px) {
                    box-shadow: inset 0 0 0 4px white;
                }
            }

            &:disabled {
                --b: var(--disabled);
                cursor: not-allowed;
                opacity: 0.9;
                box-shadow: inset 0 0 0 3px white;

                @media screen and (min-width: 1024px) {
                    box-shadow: inset 0 0 0 4px white;
                }
            }

            &:disabled:checked {
                --b: var(--disabled-inner);
                --bc: var(--border);
            }

            &:disabled+label {
                cursor: not-allowed;
            }

            &:hover:not(:checked):not(:disabled) {
                --bc: var(--border-hover);
            }

            &:not(.switch) {
                width: 21px;
                min-width: 21px;

                @media screen and (min-width: 1024px) {
                    width: 28px;
                    min-width: 28px;
                }
            }

            &:focus {
                box-shadow: 0 0 0 var(--focus);
            }

            &:not(.switch):after {
                opacity: var(--o, 0);
            }

            &:not(.switch):checked {
                --o: 1;
                background: $rgba-green;
                box-shadow: inset 0 0 0 3px white;

                @media screen and (min-width: 1024px) {
                    box-shadow: inset 0 0 0 4px white;
                }
            }

            &+label {
                cursor: pointer;
            }
        }

        //checkbox
        .btn__toggle {
            position: relative;
            display: flex;
            min-width: 44px;
            width: 44px;
            height: 24px;
            margin-right: 8px;

            @media (min-width: 768px) {
                min-width: 72px;
                width: 72px;
                height: 36px;
            }

            input {
                opacity: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                cursor: pointer;
            }

            &:has(input:focus) {
                box-shadow: 0 0 0 2px $rgba-green-light2;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #666;
                transition: all .2s ease-in-out;

                &:before {
                    position: absolute;
                    content: "";
                    height: 16px;
                    width: 16px;
                    left: 4px;
                    bottom: 4px;
                    background-color: #fff;
                    transition: all .3s ease-in-out;

                    @media(min-width:768px) {
                        height: 28px;
                        width: 28px;
                    }
                }

                &.round {
                    border-radius: 24px;

                    &:before {
                        border-radius: 50%
                    }
                }
            }

            input:checked~.slider,
            input:focus-visible:checked~.slider,
            input:focus:checked~.slider {
                background-color: $rgba-green;
            }

            input:checked~.slider:before {
                transform: translateX(20px);

                @media(min-width:768px) {
                    transform: translateX(36px);
                }
            }

            &.visuallyhidden {
                color: #fff;
            }
        }
    }

    //address
    &__addrs {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        padding: 28px 6px 16px 16px;
        background: white;
        box-shadow: inset 0 0 0 1px #e6c2ef;
        border-radius: 6px;
        margin: 0 0 12px;

        @media screen and (min-width: 1024px) {
            border-radius: 12px;
        }

        legend {
            font-size: 15px;
            font-weight: 600;
            line-height: 1.1;
            transform: translateY(24px);

            @media screen and (min-width: 1024px) {
                font-size: 17px;
            }
        }

        address,
        &>div {
            font-style: normal;
            font-size: 12px;
            font-weight: 200;
            line-height: 1.5;

            @media screen and (min-width: 1024px) {
                font-size: 14px;
            }
        }

        &--user {
            border-radius: 16px;
            box-shadow: inset 0 0 0 3px rgba(107, 21, 128, 0.4);
            padding: 16px 6px 16px 16px;

            @media screen and (min-width: 1024px) {
                padding: 32px 6px 32px 24px;
            }

            legend {
                display: none;
            }

            .e__addrs__name {
                font-size: 16px;
                font-weight: 700;
                line-height: 1;

                @media screen and (min-width: 1024px) {
                    font-size: 20px;
                }
            }

            .e__addrs__name {
                line-height: 1;
            }

            .e__addrs__wpr {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;

                @media screen and (min-width: 1024px) {
                    gap: 12px;
                }
            }

            .e__addrs__info {
                display: flex;
                flex-direction: column;
            }

            .e__addrs__ico {
                svg {
                    width: 36px;
                    height: auto;

                    @media screen and (min-width: 1024px) {
                        width: 64px;
                    }
                }
            }
        }
    }

    //buttons
    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 160px;
        height: 40px;
        padding: 0 24px;
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
        color: #112000;
        background: white;
        border: 0;
        border-radius: 20px;
        white-space: nowrap;
        cursor: pointer;
        transition: all 0.2s ease-out;

        @media screen and (min-width: 756px) {
            height: 48px;
            border-radius: 24px;
        }

        @media screen and (min-width: 1024px) {
            font-size: 16px;
            height: 56px;
            padding: 0 40px;
            border-radius: 28px;
        }

        &:hover,
        &:focus,
        &:active {
            transition: all 0.2s ease-in;
        }
        .e__btn__txt {
            font-weight: 800;
        }

        &__ico {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 30px;
            width: 30px;
            height: 30px;
            margin: 0;
            margin-right: 5px;
            border: 0;

            svg {
                transition: all .2s ease;
            }

            &:hover,
            &:focus {
                transition: all 0.2s ease-in;

                svg {
                    stroke: $rgba-green-dark3;
                }
            }
        }

        &__info {
            position: relative;
            display: inline-flex;

            .e__btn {
                width: 14px;
                height: 14px;
                border: 1px solid;
                border-radius: 50%;
                font-size: 9px;
                margin: 0;
                margin-left: 6px;
                padding: 0;
                background: transparent;

                @media screen and (min-width: 1024px) {
                    width: 16px;
                    height: 16px;
                    margin-left: 8px;
                }
            }

            &__wp {
                position: absolute;
                visibility: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                left: -76px;
                top: 14px;
                width: 220px;
                padding: 10px 8px 12px;
                font-size: 10px;
                font-weight: 500;
                line-height: 1.4;
                background: #FFFFFF;
                border: 3px solid $rgba-green-light6;
                box-shadow: 0 3px 0 0 rgba($color: #112000, $alpha: 0.25),
                    0 0.5px 0 0 rgba($color: #112000, $alpha: 0.66),
                    0 0 0 2px rgba($color: #112000, $alpha: 0.1),
                    0 0 0 0.5px rgba($color: #112000, $alpha: 0.2);
                border-radius: 5px;
                transform: scale(0) translate(0, -16px);
                transform-origin: calc(50% - 6px) 0;
                transition: transform 0.2s ease-out;
                box-sizing: border-box;
                cursor: pointer;
                z-index: 1001;

                @media screen and (min-width: 1024px) {
                    font-size: 13px;
                    left: -118px;
                    width: 320px;
                    padding: 10px 12px 12px;
                    border-radius: 10px;
                    box-shadow: 0 5px 0 0 rgba($color: #112000, $alpha: 0.25),
                        0 0.5px 0 0 rgba($color: #112000, $alpha: 0.66),
                        0 0 0 3px rgba($color: #112000, $alpha: 0.01),
                        0 0 0 0.5px rgba($color: #112000, $alpha: 0.2);
                }
            }

            .e__btn:focus+.e__btn__info__wp {
                visibility: visible;
                transform: scale(1) translate(0, -16px);
                transition: transform 0.2s ease-in;
            }
        }

        &__close {
            position: absolute;
            right: 0px;
            top: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            padding: 0;
            background: transparent;
            border-radius: 50%;
            z-index: 2;

            @media screen and (min-width: 1024px) {
                width: 48px;
                height: 48px;
            }
        }

        &__prmry {
            font-weight: 900 !important; //quitar important de main
            color: #6B1580;
            background: #F2CA0D;
            box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0),
                0 -1px 0 white,
                0 1px 0 rgba(17, 32, 0, 0.45);
            transition: all 0.3s ease-out;

            &:hover,
            &:focus,
            &:active {
                box-shadow: inset 0 0 0 500px rgba(0, 0, 0, 0.05),
                    0 1px 0 rgba(white, 0),
                    0 -0.5px 0 rgba(#6B1580, 0);
                transition: all 0.3s ease-in;

                .e__btn__txt {
                    color: #6B1580;
                }
            }

            &:active {
                box-shadow: inset 0 0 0 500px rgba(0, 0, 0, 0.05),
                    0 1px 0 rgba(white, 0.85),
                    0 -0.5px 0 rgba(#6B1580, 0.5);
                transform: translateY(1px);
            }
            .e__btn__txt {
                font-weight: 900;
            }
        }

        &__scndry {
            font-weight: 700;
            color: white;
            background: $rgba-green;

            &--mini {
                border-radius: 8px;
                margin-bottom: 0;
            }
        }

        &__trtry {
            font-weight: 700;
            border: 1px solid $rgba-green;

            &--mini {
                position: relative;
                max-width: none;
                width: 40px;
                height: 40px;
                padding: 0;
                color: #112000;
                background: white;
                border: 1px solid #DFDFDF;
                border-radius: 8px;
                margin-bottom: 0;

                @media screen and (min-width: 1024px) {
                    width: 52px;
                    height: 52px;
                }

                &:disabled {
                    visibility: hidden;
                }

                .e__btn__ico {
                    position: relative;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    min-width: 100%;
                    height: 100%;
                    margin: 0;
                    font-weight: 700;
                    background: transparent;
                    overflow: hidden;
                    border: none;
                    cursor: pointer;

                    svg {
                        width: 22px;
                        height: auto;

                        @media screen and (min-width: 1024px) {
                            width: 28px;
                            height: auto;
                        }
                    }
                }

                &:hover {
                    background: $rgba-green;

                    svg path {
                        fill: white;
                    }
                }

                &:focus {
                    background: #cef69c;
                    border: 3px solid #9bd84c;
                }
            }

            &:hover,
            &:focus,
            &:active {
                color: white;
                background: $rgba-green;

                .e__btn__txt {
                    color: white;
                }
            }
        }

        &__qtrnry {
            font-weight: 700;
            color: #6B1580;
            background: rgba(255, 255, 255, 0.3);
            border: 1px solid #D092DF;

            &:hover,
            &:focus,
            &:active {
                color: white;
                background: #A847BF;
                border: 1px solid #6B1580;

                .e__btn__txt {
                    color: white;
                    font-weight: 700;
                }

                svg path {
                    stroke: white;
                }
            }
        }
    }

    &__lnk {
        font-size: inherit;
        font-weight: 600;
        color: $rgba-green-dark2;
        text-decoration: underline;
        border: none;
        background: transparent;
    }

    //ico
    &__ico {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            display: flex;
            justify-content: center;
            align-items: center;
            block-size: auto;
            fill: none;
            inline-size: unset;
            vertical-align: unset;
            width: 22px;
            height: auto;

            @media screen and (min-width: 1024px) {
                width: 18px;
            }
        }

        &__x {
            width: 14px !important;
            height: auto;

            path {
                fill: $rgba-green-dark5;
            }
        }

        &__check {
            width: 24px !important;
            height: auto;

            path {
                fill: $rgba-green-dark1;
            }
        }
    }

    //+/-counter
    &__count {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 3px;
        font-size: 17px;
        font-weight: 800;
        color: $rgba-purple;
        background-color: $rgba-purple-light3;
        border-radius: 8px;
        @media (min-width: 1024px) {
            font-size: 20px;
            border-radius: 12px;
            padding: 5px;
        }
        &__hd {
            color: #112000;
            padding-bottom: 4px;
        }

        &__wpr {
            display: flex;
            align-items: center;
            flex: 1;
            padding-bottom: 6px;
        }

        &__minus,
        &__plus {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 56px;
            height: 56px;
            font-size: 36px;
            font-weight: 900;
            line-height: 1;
            color: $rgba-green-dark2;
            background: transparent;
            border: none;
            text-align: center;
            cursor: pointer;

            @media screen and (min-width: 1280px) {
                font-size: 48px;
            }
        }

        input {
            max-width: 80px;
            text-align: center;
        }

        input:not([type=checkbox]):not([type=radio]) {
            width: 100px;
            font-weight: 900;
            color: rgba(107, 21, 128, 1); //$rgba-purple
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance: textfield;
            /* Firefox */
        }

        &__plns {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    //swith teams
    &__switch {
        display: flex;
        padding: 10px 0 20px;

        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            padding: 3px 5px;
            border-radius: 8px;

            &:has(.active) {
                border: 1px solid #E8E9E6;
            }

            @media (min-width: 768px) {
                padding: 10px 15px 2px;
                border-radius: 12px;
            }
        }

        &__header {
            color: $rgba-green-dark2;
            font-weight: 900;
            text-transform: uppercase;
        }

        &__body {
            padding: 2px 10px;
            border-radius: 6px;
            font-weight: 900;
            line-height: 1;
            text-align: center;
            color: $rgba-purple;
            background-color: $rgba-green-light4;
        }

        &__footer {
            padding: 2px;
            font-size: 12px;
            line-height: 1;
            text-align: center;
            color: #666666;
        }

        &__btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-width: 124px;
            max-width: 180px;
            height: 40px;
            margin: 0 0 8px;
            border-radius: 8px;
            color: $rgba-purple;
            background-color: white;
            border: 1px solid $rgba-purple;
            box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
            font-size: 17px;
            font-weight: 900;
            line-height: 1;
            text-align: center;
            outline: 0;
            cursor: pointer;
            transition: all .2s ease-out;

            @media (min-width: 768px) {
                max-width: none;
                min-width: auto;
                width: 252px;
                height: 52px;
                border-radius: 12px;
                font-size: 22px;
            }

            &.active {
                color: white;
                background-color: $rgba-purple-light5;
                box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.25);
                transition: all .2s ease-in;
                cursor: default;
            }

            &:hover {
                background-color: $rgba-purple-light1;
                transition: all .2s ease-in;

                &.active {
                    background-color: $rgba-purple-light5;
                }
            }

            &:focus {
                box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1),
                    0 0 0 3px $rgba-green-light6;
                transition: all .2s ease-in;

                &.active {
                    box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.25),
                        0 0 0 3px $rgba-green-light6;
                }
            }

            &:focus-within {
                box-shadow: none;
                transition: all .2s ease-in;

                &.active {
                    box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.25);
                }
            }
        }
    }

    //faq
    &__fq {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 860px;
        margin: 0 auto;
        margin-bottom: 64px;
        padding: 0;

        @media screen and (min-width: 1024px) {
            margin-bottom: 128px;
        }

        &__hd {
            font-size: 1.25rem;
            padding-bottom: 10px;
            text-align: center;
            @media (min-width: 768px) {
                font-size: 1.5rem;
            }
        }

        &__item {
            font-size: 15px;
            line-height: 1.7;
            border: 3px solid #CEF69C;
            margin-bottom: 12px;
            border-radius: 12px;
            transition: all .2s ease;

            &:hover,
            &.act {
                border: 3px solid $rgba-green-light7;
            }

            &__hd {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                font-weight: 700;
                line-height: 1.2;
                padding: 24px 12px 25px 16px;
                cursor: pointer;
                color: $rgba-green-dark2;
                transition: all .2s ease;

                @media screen and (min-width: 1024px) {
                    font-size: 24px;
                    padding: 32px 24px 33px 32px;
                }

                &:hover {
                    text-decoration: underline;
                }

                &__hdg {
                    width: calc(100% - 20px);
                }

                &__ico {
                    svg {
                        width: 6px;
                        height: auto;
                        transform: rotate(180deg);
                        transition: all .15s ease;

                        @media screen and (min-width: 1024px) {
                            width: 8px;
                        }
                    }
                }

                .act & {
                    svg {
                        transform: rotate(270deg);
                    }
                }
            }

            &__info {
                padding: 0 16px 8px;

                @media screen and (min-width: 1024px) {
                    padding: 8px 32px 20px;
                }

                p {
                    font-size: 14px;
                    line-height: 1.45;
                    margin-bottom: 24px;

                    @media screen and (min-width: 1024px) {
                        font-size: 18px;
                        line-height: 1.5;
                        margin-bottom: 32px;
                    }
                }
            }
        }
    }

    //texto
    &__txt {
        position: relative;
        display: flex;
        flex-direction: column;

        //párrafo
        &__p {
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 0.25px;
            line-height: 1.25;
            padding: 4px 0;

            @media screen and (min-width: 1024px) {
                font-size: 15px;
            }
        }

        //informativo
        &__info {
            font-size: 11px;
            font-weight: 200;
            letter-spacing: 0.33px;
            padding: 4px 0;

            @media screen and (min-width: 1024px) {
                font-size: 13px;
            }
        }

        //legal
        &__lgl {
            font-size: 11px;
            font-weight: 200;
            text-align: center;
            letter-spacing: 0.25px;
            line-height: 1.15;

            @media screen and (min-width: 1024px) {
                font-size: 13px;
            }
        }

        //errores
        &__error {
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.25px;
            color: red;
            padding: 2px 0 0;

            @media screen and (min-width: 1024px) {
                font-size: 14px;
            }
        }
    }

    //notificación
    &__notice {
        position: relative;
        display: flex;
        justify-content: flex-start;
        font-weight: 600;
        width: 100%;
        //max-width: 1024px;
        margin: 0 0 2px;
        padding: 16px 12px 20px 16px;
        font-weight: 600;
        background: rgba($rgba-green, 0.1);
        border: 3px solid $rgba-green;
        border-radius: 16px;
        box-shadow: inset 0 0 0 4px white;

        @media screen and (min-width: 1024px) {
            margin: 0 0 3px;
            padding: 20px 12px 24px 24px;
        }

        &--error {
            background: rgba($rgba-red-light2, 0.1);
            border: 3px solid $rgba-red-light2;
     
        }

        &:last-child {
            margin: 0 0 24px;

            @media screen and (min-width: 1024px) {
                margin: 0 0 20px;
            }
        }

        &__ico {
            width: 30px;
            margin-right: 16px;

            @media screen and (min-width: 1024px) {
                margin-right: 20px;
            }

        }

        &__wpr {
            display: flex;
            flex-direction: column;
        }

        &__hd {
            font-size: 16px;
            font-weight: 900;
            color: $rgba-green;
            margin-bottom: 4px;

            @media screen and (min-width: 1024px) {
                font-size: 20px;
                margin-bottom: 6px;
            }
        }

        &__txt {
            display: inline-flex;
            flex-direction: column;
            font-size: 13px;
            line-height: 1.35;
            min-width: 100%;
            width: calc(100% - 42px);

            @media screen and (min-width: 1024px) {
                font-size: 15px;
                line-height: 1.5;
            }

            &__hlght {
                font-size: 16px;
                font-weight: 900;
                color: $rgba-green;

                @media screen and (min-width: 1024px) {
                    font-size: 19px;
                }
            }

            &>.e__notice__txt {
                display: block;
            }
        }

        .grid-profile__content & {
            max-width: none;
        }
    }

    //modal
    &__modal {
        z-index: 101;

        //background
        &__bckg {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($color: #000000, $alpha: 0.33);
            transition: all 0.2s ease-in;
        }

        //presupuesto
        //cancelar plan
        &__bdgt,
        &__cancel {
            position: fixed;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 6px);
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 0 16px 24px;
            background: white;
            border: 4px solid white;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            box-shadow: 0 -6px 0 rgba($color: #000000, $alpha: 0.33),
                inset 0 0 0 2px #A847BF,
                0 1px 0 rgba(17, 32, 0, 0.65);
            z-index: 102;

            @media screen and (min-width: 1024px) {
                bottom: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 480px;
                border-radius: 16px;
                box-shadow: 0 8px 0 rgba($color: #000000, $alpha: 0.33),
                    inset 0 0 0 3px #A847BF,
                    0 1px 0 rgba(17, 32, 0, 0.65);
            }

            @media screen and (min-width: 1024px) {
                max-width: 560px;
                border-radius: 24px;
            }

            .e__btn.e__btn__ico {
                position: absolute;
                top: 6px;
                right: 6px;
                width: 26px;
                min-width: 26px;
                height: 26px;
            }

            .e__plns__hdg__hd {
                margin-bottom: 24px;

                @media screen and (max-width: 540px) {
                    margin-bottom: 12px;
                }
            }

            &__hd {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 700;
                line-height: 1.15;
                height: 56px;
                width: 100%;
                padding-left: 5px;
                margin-top: 3px;

                //border-bottom: 3px solid #f2ddf7;
                @media screen and (min-width: 1024px) {
                    font-size: 18px;
                    padding-left: 10px;
                    margin-top: 5px;
                }

                .e__ico {
                    margin-right: 8px;

                    svg {
                        width: 28px;

                        @media screen and (min-width: 1024px) {
                            font-size: 18px;
                        }
                    }
                }
            }

            &__shd {
                font-size: 14px;
                font-weight: 700;
                line-height: 1.15;
                padding: 4px 0 2px 0;
                width: 100%;

                @media screen and (min-width: 1024px) {
                    font-size: 16px;
                }
            }

            &__wpr {
                width: 100%;
                border-radius: 16px;
                box-shadow: inset 0 0 0 3px #f2ddf7;
                padding: 20px 16px;

                .e__plns__pdf__wpr {
                    padding: 4px 0 0;
                    border-top: 1px solid #e6c2ef52;
                    border-radius: 0;
                }

                .e__txt__p .e__lnk {
                    width: 100%;
                    justify-content: center;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__ftr {
                display: flex;
                width: 100%;
                margin-top: -8px;
            }

            .e__plns__pdf {
                background: transparent;
                box-shadow: none;
                margin-bottom: 0;
                margin-top: 20px;
                padding: 0;

                &__wpr {
                    box-shadow: none;
                }
            }

            .e__plns__link {
                flex: 1;
                margin: 0;
                padding: 48px 20px 16px;

                legend {
                    transform: translate(-12px, 34px);
                }
            }
        }

        //cancelar plan
        &__cancel {
            &__hd {
                margin: 0;
            }

            &__wpr {
                border: 0;
                padding: 0;
            }

            &__foot {
                display: flex;

                &>* {
                    min-width: 128px;
                    max-width: 128px;
                }
            }
        }

        //terminos y condiciones / video
        &__lcns,
        &__vid {
            position: fixed;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 76%;
            padding: 0 0 20px;
            padding-bottom: 2px;
            background: white;
            border: 4px solid white;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            box-shadow: 0 -6px 0 rgba($color: #000000, $alpha: 0.33),
                inset 0 0 0 2px $rgba-green,
                0 1px 0 rgba(17, 32, 0, 0.65);
            z-index: 102;

            @media screen and (min-width: 1024px) {
                bottom: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, calc(-50% + 32px));
                max-width: 780px;
                height: 80%;
                border-radius: 16px;
                padding-bottom: 3px;
                padding-top: 3px;
                border-radius: 24px;
                box-shadow: 0 8px 0 rgba($color: #000000, $alpha: 0.33),
                    inset 0 0 0 3px $rgba-green,
                    0 1px 0 rgba(17, 32, 0, 0.65);
            }

            iframe {
                border: 0;
                width: 100%;
                height: 100%;
            }
        }

        //video
        &__vid {
            @media screen and (min-width: 1024px) {
                width: 90%;
                height: auto;
            }

            &__wpr {
                position: relative;
                padding-bottom: 56.25%;
                /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
                height: 0;
                overflow: hidden;
                width: 100%;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;

                @media screen and (min-width: 1024px) {
                    border-radius: 20px;
                }
            }

            iframe {
                position: absolute;
                top: 0;
                left: 0;
            }

            .e__btn__close {
                background: rgba(255, 255, 255, 0.1);

                path {
                    fill: $rgba-green;
                }
            }
        }

        //notificación
        &__notice {
            position: fixed;
            top: 16px;
            left: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 12px 0;
            padding: 12px;
            background: #FFFFFF;
            border: 2px solid;
            box-shadow: 0 3px 0 rgba($color: #000000, $alpha: 0.33);
            transform: translate(-50%, 0);

            &__ico {
                margin-right: 12px;
            }

            &__wpr {
                display: flex;
                justify-content: center;
                align-items: center;

                .e__ico {
                    width: 12px;
                    height: 12px;
                }
            }

            &__txt {
                font-size: 13px;
                line-height: 1.15;
                min-width: 160px;
            }

            &__btn {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-left: 16px;
                cursor: pointer;

                &__txt {
                    font-size: 10px;
                    margin-top: 8px;
                }
            }
        }
    }

    //heading
    &__hd {  
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 40px;
        padding: 5px 0 0;
        .e__hd__nv ~ & {
            padding: 0px 10px;
        }
        @media screen and (min-width: 768px) {
            padding: 40px 30px 15px;
            .e__hd__nv ~ & {
                padding: 0px 30px 15px;
            }
            .e__plns__opt & {
                margin-top: 0;
            }
        }

        &__nv {
            display: flex;
            align-items: center;
            padding: 12px 0;

            @media screen and (min-width: 1024px) {
                padding: 16px 0;
            }

            &__bck {
                height: 24px;
                border: 0;
                background: none;

                @media screen and (min-width: 1024px) {
                    height: 32px;
                }

                &__ico {
                    display: flex;
                    margin-right: 12px;

                    svg {
                        width: 8px;
                        height: auto;

                        @media screen and (min-width: 1024px) {
                            width: 10px;
                        }
                    }
                }

                &__txt {
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 1;
                    text-decoration: none;

                    @media screen and (min-width: 1024px) {
                        font-size: 17px;
                    }
                }

                &:hover .e__hd__nv__bck__txt {
                    text-decoration: underline;
                }
            }
        }

        &__wrp {
            display: flex;
            flex-direction: column;
            align-items: center;

            .grid-profile__content & {
                @media screen and (min-width: 778px) {
                    align-items: flex-start;
                }
            }
        }

        &__hdg {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1;
            white-space: nowrap;
            margin: 0;
            text-align: center;

            @media screen and (min-width: 855px) {
                font-size: 1.5rem;
                text-align: center;
            }

            @media screen and (max-width: 540px) {
                font-size: 20px;
            }
        }

        .grid-profile__content & {
            margin-top: 20px;

            @media screen and (min-width: 855px) {
                margin-top: 40px;
                margin-bottom: 0;
            }
        }
    }

    ///////////Planes Educaplay
    &__plns {
        padding: 12px 16px;

        //componentes
        &__hdg {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-bottom: 12px;
            transition: all .2s ease-out;

            @media screen and (max-width: 540px) {
                align-items: center;
            }

            &__hd {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                line-height: 0.95;
                color: rgba(107, 21, 128, 1); //$rgba-purple

                &__ico {
                    margin-right: 6px;
                    padding-bottom: 4px;

                    svg {
                        width: 18px;
                        height: auto;
                        filter: drop-shadow(0 1px 0 rgba(107, 21, 128, 0.5));

                        @media screen and (min-width: 756px) {
                            width: 22px;
                        }

                        @media screen and (min-width: 1280px) {
                            width: 26px;
                        }

                        path {
                            fill: $rgba-yellow;
                        }
                    }
                }

                &__main {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                }

                &__shdg {
                    font-size: 7px;
                    font-weight: 300;
                    text-transform: uppercase;
                    padding: 0;

                    @media screen and (min-width: 560px) {
                        font-size: 8px;
                    }

                    @media screen and (min-width: 756px) {
                        font-size: 10px;
                    }

                    @media screen and (min-width: 1280px) {
                        font-size: 12px;
                    }
                }

                &__hdg {
                    font-size: 22px;
                    font-weight: 900;
                    letter-spacing: 0;
                    word-break: normal;

                    @media screen and (min-width: 560px) {
                        font-size: 24px;
                    }

                    @media screen and (min-width: 756px) {
                        font-size: 28px;
                    }

                    @media screen and (min-width: 1280px) {
                        font-size: 32px;
                    }
                }

                &__lcns,
                &__perid {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.1;
                    letter-spacing: 0;
                    white-space: nowrap;

                    @media screen and (min-width: 560px) {
                        font-size: 18px;
                    }

                    @media screen and (min-width: 756px) {
                        font-size: 20px;
                        line-height: 1.15;
                    }

                    @media screen and (min-width: 1280px) {
                        font-size: 24px;
                    }
                }

                &__rol {
                    font-size: 12px;
                    font-weight: 300;

                    @media screen and (min-width: 560px) {
                        font-size: 13px;
                    }

                    @media screen and (min-width: 756px) {
                        font-size: 15px;
                    }

                    @media screen and (min-width: 1280px) {
                        font-size: 16px;
                    }

                    span {
                        padding-right: 6px;

                        &::after {
                            content: " + ";
                            display: inline-block;
                            padding-left: 6px;
                        }
                    }
                }
            }

            &__wpr {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                @media screen and (max-width: 540px) {
                    align-items: center;
                    flex-direction: column-reverse;
                    width: 100%;
                }

                &>.e__btn__trtry--mini {
                    @media screen and (max-width: 540px) {
                        margin: 0;
                        align-self: flex-end;
                    }
                }

                .grid-profile__content & {
                    @media screen and (max-width: 1023px) {
                        flex-direction: column-reverse;
                        align-items: flex-end;
                    }
                }

                &>.e__lnk {
                    @media screen and (max-width: 540px) {
                        margin: 0 auto 8px;
                    }
                }
            }

            //contenedor mini planes
            &__wrp {
                margin: 0 auto;

                &__lst {
                    display: inline-flex;
                    flex-wrap: wrap;
                    gap: 24px;

                    @media screen and (min-width: 541px) {
                        row-gap: 12px;
                    }

                    @media screen and (min-width: 1024px) {
                        gap: 24px;
                    }

                    @media screen and (max-width: 540px) {
                        flex-direction: column;
                        align-items: center;
                        gap: 12px;
                    }

                    &__plan {
                        position: relative;
                        display: flex;
                        align-items: flex-end;
                        width: auto;
                        .e__plns__hdg__hd {
                            justify-content: flex-start;
                            align-items: flex-start;
                            background: white;
                            border-radius: 8px;
                            padding: 5px;

                            @media screen and (min-width: 1024px) {
                                padding: 10px;
                            }

                            &--0 {
                                .e__plns__hdg__hd__ico {
                                    display: none;
                                }
                            }

                            .e__plns__hdg__hd {
                                &__ico {
                                    padding: 0;
                                    margin-top: 6px;
                                    margin-right: 4px;

                                    @media screen and (max-width: 1919px) {
                                        margin-top: 7px;
                                    }

                                    @media screen and (max-width: 1919px) {
                                        margin-top: 9px;
                                    }

                                    @media screen and (max-width: 1023px) {
                                        margin-top: 9px;
                                    }

                                    @media screen and (max-width: 540px) {
                                        margin-top: 6px;
                                    }

                                    @media screen and (min-width: 1920px) {
                                        margin-top: 11px;
                                    }

                                    svg {
                                        width: 14px;

                                        @media screen and (max-width: 1919px) {
                                            width: 15px;
                                        }

                                        @media screen and (max-width: 1023px) {
                                            width: 14px;
                                        }

                                        @media screen and (max-width: 540px) {
                                            width: 14px;
                                        }

                                        @media screen and (min-width: 1920px) {
                                            width: 19px;
                                        }
                                    }
                                }

                                &__wrp {
                                    display: flex;
                                    align-items: flex-end;
                                    gap: 5px;
                                }

                                &__shdg {
                                    @media screen and (max-width: 1919px) {
                                        font-size: 9px;
                                    }

                                    @media screen and (max-width: 1023px) {
                                        font-size: 8px;
                                    }

                                    @media screen and (max-width: 540px) {
                                        font-size: 7px;
                                    }

                                    @media screen and (min-width: 1920px) {
                                        font-size: 9px;
                                    }
                                }

                                &__hdg {
                                    grid-column: 1 / 3;

                                    @media screen and (max-width: 1919px) {
                                        font-size: 22px;
                                    }

                                    @media screen and (max-width: 1023px) {
                                        font-size: 20px;
                                    }

                                    @media screen and (max-width: 540px) {
                                        font-size: 19px;
                                    }

                                    @media screen and (min-width: 1920px) {
                                        font-size: 24px;
                                    }
                                }

                                &__lcns,
                                &__perid {
                                    grid-column: 1;

                                    @media screen and (max-width: 1919px) {
                                        font-size: 14px;
                                    }

                                    @media screen and (max-width: 1023px) {
                                        font-size: 13px;
                                    }

                                    @media screen and (max-width: 540px) {
                                        font-size: 12px;
                                    }

                                    @media screen and (min-width: 1920px) {
                                        font-size: 16px;
                                    }
                                }

                                &__perid {
                                    //font-weight: 200;
                                    grid-column: 2;
                                    padding-left: 4px;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }

                                &__rol {
                                    @media screen and (max-width: 1919px) {
                                        font-size: 13px;
                                    }

                                    @media screen and (max-width: 1023px) {
                                        font-size: 12px;
                                    }

                                    @media screen and (max-width: 540px) {
                                        font-size: 11px;
                                    }

                                    @media screen and (min-width: 1280px) {
                                        font-size: 15px;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            .e__plns__lst__hd__ico {
                                display: none;
                            }
                        }

                        &:only-child {
                            width: auto;  
                            flex-direction: column;
                            align-items: center;

                            .e__plns__lst__hd__hdg {
                                //display: none;
                                text-align: center;
                                padding: 0 5px 5px
                            }

                            .e__plns__hdg__hd {
                                &__ico {
                                    margin-top: 13px;

                                    @media screen and (max-width: 540px) {
                                        margin-top: 7px;
                                    }

                                    svg {
                                        width: 24px;

                                        @media screen and (max-width: 540px) {
                                            width: 16px;
                                        }
                                    }
                                }
                            }

                            .e__plns__lst__hd__ico {
                                display: none;
                            }

                            .e__plns__hdg__hd {
                                &__shdg {
                                    font-size: 7px;

                                    @media screen and (min-width: 756px) {
                                        font-size: 8px;
                                    }

                                    @media screen and (min-width: 1280px) {
                                        font-size: 10px;
                                    }
                                }

                                &__hdg {
                                    font-size: 22px;

                                    @media screen and (min-width: 756px) {
                                        font-size: 24px;
                                    }

                                    @media screen and (min-width: 1280px) {
                                        font-size: 28px;
                                    }
                                }

                                &__lcns,
                                &__perid {
                                    font-size: 16px;

                                    @media screen and (min-width: 560px) {
                                        font-size: 18px;
                                    }

                                    @media screen and (min-width: 756px) {
                                        font-size: 20px;
                                    }

                                    @media screen and (min-width: 1280px) {
                                        font-size: 24px;
                                    }
                                }
                            }

                            .e__plns__hdg__hd--0,
                            .e__plns__hdg__hd--1,
                            .e__plns__hdg__hd--2,
                            .e__plns__hdg__hd--3 {
                                border: 0;
                                background: transparent;
                            }

                            .grid-profile__content & {
                                @media screen and (max-width: 540px) {
                                    padding-top: 64px;
                                }
                            }
                        }
                    }
                }
            }

            //boton cancelar plan
            &>.e__lnk {
                position: relative;
                width: fit-content;
                left: 50%;
                transform: translateX(-50%);
                font-weight: 600 !important;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 32px;

                @media screen and (max-width: 540px) {
                    text-align: center;
                    margin-top: 8px;
                }

                .grid-profile__content & {
                    margin: 0 auto 32px;

                    @media screen and (max-width: 540px) {
                        margin: 0 auto;
                    }

                    @media screen and (min-width: 1280px) {
                        margin: 12px auto 32px;
                    }
                }
            }

            &.act {
                margin-bottom: 2px;

                .e__btn__trtry--mini {
                    background: #cef69c;
                    border: 3px solid #9bd84c;
                }
            }

            .grid-profile__content & {
                margin-bottom: 32px;

                &.act .e__plns__hdg__wrp {
                    margin: 0 auto;
                    transform: translateX(0);
                }
            }
        }

        //precios
        &__vlue {
            grid-column: 1/3;
            grid-row: 3;
            width: 100%;
            font-weight: 700;
            margin-bottom: 6px;

            &__end,
            &__iva,
            &__ttl {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                border-bottom: 1px solid rgba(black, 0.05);
                box-shadow: 0 1px 0 rgba(white, 0.2);
                line-height: 1.05;
                padding: 4px 0;

                @media screen and (min-width: 1024px) {
                    height: 34px;
                }

                &__txt {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    gap: 4px;
                    margin-right: 16px;
                    font-size: 11px;
                    letter-spacing: -0.2px;
                    line-height: 1.15;

                    @media screen and (min-width: 1024px) {
                        font-size: 13px;
                    }

                    @media screen and (min-width: 1280px) {
                        font-size: 14px;
                    }

                    &__now,
                    &__tot {
                        font-weight: 600;
                    }

                    &__tax {
                        font-weight: 300;
                    }
                }

                &__info {
                    font-size: 14px;
                    text-align: right;
                    line-height: 1;
                    flex: 1;
                    white-space: nowrap;

                    &__nmbr {
                        font-size: 21px;
                        font-weight: 400;

                        @media screen and (min-width: 1024px) {
                            font-size: 24px;
                        }
                    }

                    span:last-child {
                        padding-left: 1px;

                        @media screen and (min-width: 1024px) {
                            padding-left: 2px;
                        }
                    }
                }

                &--hlght {

                    .e__plns__vlue__iva__info__nmbr,
                    .e__plns__vlue__tttl__info__nmbr,
                    .e__plns__vlue__end__info__nmbr {
                        font-size: 26px;
                        font-weight: 900;

                        @media screen and (min-width: 1024px) {
                            font-size: 32px;
                        }
                    }
                }

                &:last-child {
                    border-bottom: none;
                    box-shadow: none;
                }
            }

            &__iva {
                &__info {
                    flex: 1;
                    white-space: nowrap;
                }
            }

            &__ttl:last-child {
                border: 0;
                box-shadow: none;
            }

            &>div:first-child .e__plns__vlue__end__txt {
                white-space: break-spaces;
            }
        }

        //descuentos textos
        &__dscount {
            display: flex;
            align-items: center;
            font-size: 10px;
            font-weight: 700;
            line-height: 1;
            letter-spacing: 0;
            text-align: center;
            color: $rgba-black;
            text-transform: uppercase;

            @media screen and (min-width: 1024px) {
                font-size: 11px;
            }

            &__nmbr {
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: $rgba-green-dark3;
                background: $rgba-green-light5;
                border-radius: 24px;
                font-size: 14px;
                font-weight: 900;
                letter-spacing: 0.5px;
                margin-right: 3px;
                padding: 2px 4px 1px 6px;

                @media screen and (min-width: 1024px) {
                    font-size: 16px;
                    letter-spacing: 0.75px;
                }
            }
        }

        //administrador licencias
        &__admin {
            display: flex;
            flex-direction: column;
            margin-bottom: 96px;
            padding: 20px 16px;
            overflow: hidden;
            transition: all .2s ease-out;

            &__numb {
                display: flex;
                align-items: center;
                height: 48px;
                margin-top: -20px;
                margin-left: auto;
                padding: 0 0 0 12px;
                font-size: 20px;
                font-weight: 600;
                background: white;

                span {
                    margin-right: 8px;
                }
            }

            &__hd {
                display: flex;
                margin: 24px 0;
                padding-bottom: 8px;
                border-bottom: 1px solid;

                &__numb,
                &__inpt,
                &__chck {
                    font-size: 12px;
                    text-align: center;
                }

                &__numb {
                    width: 32px;
                }

                &__inpt {
                    flex: 1;
                }

                &__chck {
                    width: 52px;
                }
            }

            &__wpr {
                &>h6 {
                    padding-bottom: 16px;
                }

                .e__form {
                    margin-bottom: 24px;

                    &__item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 100%;
                        height: 56px;

                        label {
                            position: absolute !important;
                            opacity: 0 !important;
                            width: 0 !important;
                            height: 0 !important;
                            overflow: hidden !important;
                        }

                        &__col1 {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 32px;
                        }

                        &__col2 {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex: 1;

                            input {
                                width: 100%;
                            }
                        }

                        &__col3 {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 52px;
                            margin-left: 12px;
                        }

                        &__ico {
                            position: absolute;
                            top: calc(50% - 8px);
                            right: 8px;
                            display: none;
                            justify-content: center;
                            align-items: center;
                            width: 20px;
                            height: 20px;
                            background: white;
                            z-index: 2;
                        }

                        .e__chck {
                            input {
                                margin: 0;
                            }
                        }
                    }
                }

                .e__btn__primary {
                    margin: auto;
                }
            }
        }

        //factura
        //pagar por mi
        //descargar presupuesto
        &__fact,
        &__link,
        &__pdf {
            position: relative;
            display: flex;
            flex-direction: column;
            transition: all .2s ease-out;

            @media screen and (min-width: 1024px) {
                border-radius: 16px;
            }

            &__hdg {
                display: flex;
                flex-direction: column;

                &__hd {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .e__btn__ico {
                        margin-left: 12px;
                    }

                    label {
                        font-weight: 700;
                    }
                }
            }

            &__wpr {
                display: flex;
                flex-direction: column;
                padding: 12px 12px;
                border-radius: 20px;
                background: white;

                @media screen and (max-width: 540px) {
                    border-radius: 12px;
                }
            }

            &.mty {
                .e__form {
                    display: flex;
                }
            }
        }

        //factura
        &__fact {
            margin: 20px 0 0;

            @media screen and (min-width: 1024px) {
                margin: 40px 0 0;
            }

            &__lst {
                display: flex;
                flex-direction: column;

                &__wpr {
                    margin-bottom: 6px;

                    &__item {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        font-weight: 700;
                        color: #c6c6c6;
                        box-shadow: inset 0 0 0 1px #E6C2EF;
                        border-radius: 6px;
                        margin: 0 0 2px;
                        padding: 4px 4px 4px 16px;

                        @media screen and (min-width: 1024px) {
                            font-size: 16px;
                            margin: 0 0 4px;
                        }

                        &:hover,
                        &:focus,
                        &:active,
                        &:focus-visible {
                            box-shadow: inset 0 0 0 3px #A847BF;
                        }

                        &__code,
                        &__date {
                            color: #112000;
                        }

                        &__date {
                            font-weight: 300;
                            margin-left: 8px;
                        }

                        .e__btn {
                            margin-left: auto;
                        }
                    }

                    &~.e__lnk {
                        padding-left: 4px;
                        margin-bottom: 8px;

                        @media screen and (max-width: 540px) {
                            font-size: 14px;
                            align-items: center;
                            flex-direction: column-reverse;
                            width: 100%;
                        }
                    }
                }
            }

            &__hdg {
                .e__chck {
                    margin: 0 8px 0 0;

                    @media screen and (max-width: 540px) {
                        margin: 0 8px;
                    }

                    label {
                        padding: 0 0 0 12px;

                        @media screen and (max-width: 540px) {
                            padding: 0;
                        }
                    }

                    .btn__toggle {
                        margin: 0;
                    }
                }
            }

            &__wpr {
                box-shadow: inset 0 0 0 1px #E6C2EF;

                .e__addrs {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    grid-template-rows: auto;
                    margin-bottom: 16px;
                    padding: 6px 6px 16px 16px;
                    box-shadow: inset 0 0 0 1px #e6c2ef;
                    border-radius: 6px;
                    margin: 0 0 12px;

                    @media screen and (min-width: 1024px) {
                        border-radius: 12px;
                    }

                    legend {
                        grid-column: 1;
                        grid-row: 1;
                        align-self: center;
                        justify-content: flex-start;
                        padding: 0;
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 1.1;
                        transform: translateY(32px);

                        @media screen and (min-width: 1024px) {
                            font-size: 17px;
                            transform: translateY(40px);
                        }
                    }

                    address,
                    &>div {
                        grid-column: 1/3;
                        grid-row: 2;
                        font-style: normal;
                        font-size: 12px;
                        font-weight: 200;
                        line-height: 1.5;

                        @media screen and (min-width: 1024px) {
                            font-size: 14px;
                        }
                    }

                    button{
                        grid-column: 2;
                        grid-row: 1;
                        margin-left: 4px;
                    }
                }
            }

            .e__form {
                transform-origin: 0 0;
                transform: scale(1, 0);
                animation: topTobottom 0.2s ease-in-out forwards;
                margin-top: 20px;
                padding: 0 12px;

                @media screen and (max-width: 540px) {
                    margin-top: 12px;
                    padding: 0;

                    &__item {
                        label {
                            display: flex;
                            width: 0px;
                            height: 0px;
                            overflow: hidden;
                            opacity: 0;
                        }

                        input {
                            &::-webkit-input-placeholder {
                                color: #000000;
                            }

                            &:focus::-webkit-input-placeholder {
                                color: #BBBBBB;
                            }
                        }
                    }
                }

                &__item {
                    position: relative;

                    &--link {
                        margin-bottom: 8px;
                    }

                    &__ico {
                        top: calc(50% - 9px);

                        @media screen and (min-width: 540px) {
                            top: calc(50% - 2px);
                        }

                        @media screen and (min-width: 1024px) {
                            top: calc(50% - 3px);
                        }
                    }

                    .e__txt__error~.e__form__item__ico {
                        top: calc(50% - 19px);

                        @media screen and (min-width: 540px) {
                            top: calc(50% - 12px);
                        }

                        @media screen and (min-width: 1024px) {
                            top: calc(50% - 15px);
                        }
                    }
                }
            }
        }

        //descargar presupuesto
        &__pdf {
            display: flex;
            margin-bottom: 24px;

            @media screen and (min-width: 1024px) {
                margin-bottom: 32px;
            }

            @media screen and (min-width: 1280px) {
                padding: 0 20%;

            }

            @media screen and (min-width: 1920px) {
                padding: 0 25%;
            }

            &__wpr {
                border-radius: 16px;
                padding: 12px 20px 20px;
                box-shadow: inset 0 0 0 3px #E6C2EF;

                @media screen and (min-width: 1024px) {
                    padding: 16px 20px 20px;
                    border-radius: 20px;
                }

                .e__txt__info {
                    letter-spacing: -0.25px;
                    text-align: center;
                    padding: 6px 12px 16px;
                }

                &>.e__btn {
                    min-width: 320px;
                    margin: 0 auto;

                    @media screen and (max-width: 540px) {
                        min-width: 240px;
                        max-width: 100%;
                    }
                }
            }
        }

        //pagar por mi
        &__link {
            border-radius: 16px;
            padding: 64px 32px 16px;
            margin-bottom: 16px;

            @media screen and (min-width: 1024px) {
                border-radius: 20px;
                margin-bottom: 24px;
            }

            @media screen and (min-width: 1280px) {
                margin: 0 20%;

            }

            @media screen and (min-width: 1920px) {
                margin: 0 25%;
            }

            legend {
                position: relative;
                display: flex;
                align-items: flex-end;
                color: #6B1580;
                font-size: 20px;
                font-weight: 700;
                padding-bottom: 12px;
                width: auto;
                margin: 0 auto;
                transform: translate(-12px, 40px);
                z-index: 2;

                .e__btn__info {
                    margin-bottom: 4px;
                }
            }

            &__ico {
                margin-right: 12px;

                svg {
                    width: 40px;
                    height: auto;
                }
            }

            &__wpr {
                background: transparent;
                margin-top: -8px;
                padding: 0;
            }

            .e__txt__info {
                //text-align: center;
                letter-spacing: -0.25px;
                padding-top: 0;
                font-weight: 600;
                color: #6B1580;
            }

            .e__form__item--link {
                padding: 2px 2px 2px 0;
                margin-bottom: 0;
                border: 1px solid #112000;
                border-radius: 5px;
                background: white;
                transition: width 0.2s ease-in-out;

                @media screen and (min-width: 1024px) {
                    padding: 2px 4px 2px 1px;
                }

                &:hover,
                &:focus,
                &:active {
                    box-shadow: inset 0 0 0 1px #112000;
                }
            }

            .e__form {
                display: flex;
                flex-direction: column;
                margin: 0;

                &__item {
                    &__wpr {
                        position: relative;
                    }
                }

                input:not([type=checkbox]):not([type=radio]):not([type=range]),
                textarea {
                    font-size: 12px;
                    border: 0;
                    box-shadow: none;

                    @media screen and (min-width: 1024px) {
                        font-size: 14px;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        border: 0;
                        box-shadow: none;
                    }
                }
            }

            &--1 {
                background: rgba($rgba-purple-advanced, 0.5);
                border: 1px solid #F2DDF7;
                border-top: 0;
                box-shadow: 0 2px 0 rgba(17, 32, 0, 0.10),
                    0 0.5px 0 rgba(17, 32, 0, 0.35); // $rgba-green-dark5

                @media screen and (min-width: 1024px) {
                    box-shadow: 0 3px 0 rgba(17, 32, 0, 0.10),
                        0 0.5px 0 rgba(17, 32, 0, 0.35); // $rgba-green-dark5
                }
            }

            &--2 {
                background: rgba($rgba-purple-academic, 0.5);
                border: 1px solid #DEADEA;
                border-top: 0;
                box-shadow: 0 2px 0 rgba(17, 32, 0, 0.15),
                    0 0.5px 0 rgba(17, 32, 0, 0.4); // $rgba-green-dark5

                @media screen and (min-width: 1024px) {
                    box-shadow: 0 3px 0 rgba(17, 32, 0, 0.15),
                        0 0.5px 0 rgba(17, 32, 0, 0.4); // $rgba-green-dark5
                }
            }

            &--3 {
                background: rgba($rgba-purple-commercial, 0.5);
                border: 1px solid rgba(168, 71, 191, 0.3);
                border-top: 0;
                box-shadow: 0 2px 0 rgba(17, 32, 0, 0.20),
                    0 0.5px 0 rgba(17, 32, 0, 0.45); // $rgba-green-dark5

                @media screen and (min-width: 1024px) {
                    box-shadow: 0 3px 0 rgba(17, 32, 0, 0.20),
                        0 0.5px 0 rgba(17, 32, 0, 0.45); // $rgba-green-dark5
                }
            }
        }

        ///////////PAGINAS
        //listado planes
        &__lst {
            display: flex;
            flex-direction: column;
            padding: 0;

            .e__hd {
                line-height: 1;
                margin-bottom: 0;
                padding: 20px 10px 0;
                @media (min-width: 768px) {
                    padding: 40px 30px 0;
                }
                &__wrp {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    @media (min-width: 768px) {
                        height: 48px;
                    }
                }
            }

            .e__plns__hdg__wrp {
                transform: none;

                @media screen and (max-width: 1023px) {
                    margin-top: 4px;
                }
            }

            &__notice {
                display: flex;
                flex-direction: column;
                align-items: center;

                @media screen and (min-width: 1024px) {
                    padding: 0 32px;
                }

                .grid-profile__content & {
                    align-items: flex-start;
                    margin: 0 0 12px;
                    padding: 0;
                    width: 100%;
                    max-width: none;

                    &:last-child {
                        margin: 0 0 20px;
                    }
                }
            }

            &__hd {
                display: flex;
                gap: 20px;
                margin: 0 auto 0;

                @media screen and (min-width: 1024px) {
                    gap: 24px;
                }

                @media screen and (max-width: 1023px) {
                    flex-direction: column;
                }

                &__hdg {
                    font-size: 11px;
                    font-weight: 200;
                    line-height: 1.15;
                    text-align: left;
                    padding: 8px 5px;
                    min-width: fit-content;

                    @media (min-width: 1024px) {
                        font-size: 13px;
                        padding: 11px 10px;
                    }
                }

                &__ico {
                    position: absolute;
                    top: calc(100% + 6px);
                    left: 50%;
                    transform: translate(0, -50%) rotate(-90deg);

                    @media screen and (min-width: 541px) {
                        top: calc(50% + 7px);
                        left: auto;
                        right: -16px;
                        transform: translateY(-50%) rotate(180deg);
                    }

                    svg {
                        width: 8px;
                        height: auto;

                        @media screen and (max-width: 540px) {
                            width: 6px;
                        }

                        path {
                            fill: $rgba-purple-light4;
                        }
                    }
                }

                &+.e__btn__prmry {
                    max-width: none;
                    width: 280px;
                    margin: 36px auto 48px;
                }

                //sólo para notificación de cambio y compra
                &__item {
                    min-width: 100%;
                    border-radius: 20px;
                    padding: 12px 16px;

                    @media screen and (min-width: 1024px) {
                        min-width: 480px !important;
                    }

                    &--0 {
                        background: white;
                        border: 2px solid rgba(168, 71, 191, 0.1);

                        .e__plns__hdg__hd__ico {
                            display: none;
                        }
                    }

                    &--1 {
                        background: $rgba-purple-advanced;
                        border: 2px solid rgba($rgba-purple-light5, 0.1);
                    }

                    &--2 {
                        background: $rgba-purple-academic;
                        border: 2px solid rgba($rgba-purple-light5, 0.2);
                    }

                    &--3 {
                        background: $rgba-purple-commercial;
                        border: 2px solid rgba($rgba-purple-light5, 0.3);
                    }

                    .e__plns__hdg__hd {
                        display: flex;
                        background: transparent !important;
                        border: 0 !important;
                        padding-left: 0 !important;
                        margin: 0;
                    }

                    .e__plns__hdg__hd__box {
                        display: inline-flex;
                        gap: 2px;
                        line-height: 1;
                        width: -webkit-fill-available;
                    }

                    .e__plns__lst__hd__ico {
                        top: calc(100% + 7px);

                        @media screen and (min-width: 1024px) {
                            top: calc(50% + 7px);
                            right: -16px;
                        }

                        svg {
                            width: 8px;

                            @media screen and (min-width: 1024px) {
                                width: 11px;
                            }
                        }
                    }

                    .e__plns__hdg__hd__new {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin-top: 16px;
                        gap: 8px;

                        &__info {
                            margin-bottom: 6px;
                        }

                        .e__addrs {
                            margin: 0;
                            margin-top: -20px;
                        }

                        &__btn {
                            display: flex;
                            justify-content: center;
                            margin-top: 12px;

                            button {
                                min-width: 280px;
                            }
                        }
                    }
                }
            }

            &__main {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0 16px;

                .e__count {
                    margin: 32px 0 12px;

                    @media screen and (min-width: 1920px) {
                        margin: 64px 0 16px;
                    }

                    &__hd {
                        font-size: 15px;
                        font-weight: 700;
                        color: $rgba-purple;

                        @media screen and (min-width: 1024px) {
                            font-size: 17px;
                        }
                    }

                    .grid-profile__content & {
                        @media screen and (max-width: 1480px) {
                            margin: 16px auto 16px 0;
                        }
                    }
                }

                .e__txt__lgl {
                    margin-bottom: 40px;

                    @media screen and (min-width: 1024px) {
                        margin-bottom: 80px;
                    }
                }

                &__nv {
                    display: flex;
                    font-size: 15px;
                    font-weight: 700;
                    margin-bottom: 96px;

                    @media screen and (min-width: 1024px) {
                        font-size: 20px;
                        margin-bottom: 112px;
                    }

                    &>button {
                        text-decoration: underline;
                        color: $rgba-green-dark2;
                        background: transparent;
                        margin: 8px 16px;
                        border: 0;
                    }

                    .grid-profile__content & {
                        margin: 0 auto 112px;
                    }
                }

                .grid-profile__content & {
                    align-items: self-start;
                    padding: 0;
                }
            }

            &__plan {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-auto-rows: auto;
                width: 100%;
                max-width: 500px;
                border-radius: 12px;
                padding: 8px 20px 22px;
                margin: 0 auto 12px auto;

                @media screen and (min-width: 1024px) {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    align-items: center;
                    justify-items: stretch;
                    width: 25%;
                    max-width: 380px;
                    //height: 570px;
                    margin: 0 0 16px;
                    padding: 20px 16px 24px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @media screen and (min-width: 1920px) {
                    padding: 32px 16px 24px;
                }

                @media screen and (max-width: 540px) {
                    grid-template-columns: 1fr auto;
                }

                .e__plns__hdg__hd {
                    margin: 16px auto auto 0;

                    @media screen and (min-width: 1024px) {
                        margin: 0 auto 12px;
                    }
                }

                &--0 {
                    padding-top: 24px;
                    background: white;
                    border: 2px solid rgba(168, 71, 191, 0.1);
                    box-shadow: 0 4px 0 rgba(17, 32, 0, 0), 0 0.5px 0 rgba(17, 32, 0, 0); // $rgba-green-dark5

                    @media screen and (max-width: 1023px) {
                        grid-template-columns: 1fr;
                    }

                    .e__plns__lst__plan__info__item {
                        @media screen and (max-width: 540px) {
                            justify-content: center;
                        }
                    }

                    .e__plns__hdg__hd {
                        margin: 0 auto !important;

                        @media screen and (max-width: 540px) {
                            margin: 0 auto 8px !important;
                        }
                    }

                    .e__plns__lst__plan__btns {
                        justify-content: center;

                        .e__btn__scndry {
                            min-width: 240px;

                            @media screen and (min-width: 1024px) {
                                margin-bottom: 0;
                                margin-top: 24px;
                            }

                            @media screen and (min-width: 1920px) {
                                margin-top: 84px;
                            }
                        }
                    }

                    .e__plns__lst__plan__info {
                        width: 270px;
                        margin: 12px auto;

                        @media screen and (min-width: 1024px) {
                            margin-top: 126px;
                        }

                        @media screen and (min-width: 1920px) {
                            margin-top: 202px;
                        }

                        @media screen and (max-width: 540px) {
                            width: 100%;
                            margin-bottom: 16px;
                        }
                    }
                }

                &--1 {
                    background: $rgba-purple-advanced;
                    border: 1px solid rgba($rgba-purple-light5, 0.1);
                    box-shadow: 0 4px 0 rgba(17, 32, 0, 0.25), 0 0.5px 0 rgba(17, 32, 0, 0.8); // $rgba-green-dark5

                    .e__plns__hdg__hd {
                        @media screen and (min-width: 1024px) {
                            transform: translateX(-11px);
                        }

                        @media screen and (min-width: 1280px) {
                            transform: translateX(-14px);
                        }
                    }
                }

                &--2 {
                    background: $rgba-purple-academic;
                    border: 1px solid rgba($rgba-purple-light5, 0.2);
                    box-shadow: 0 4px 0 rgba(17, 32, 0, 0.35), 0 0.5px 0 rgba(17, 32, 0, 0.8); // $rgba-green-dark5

                    .e__plns__dscount {
                        color: $rgba-black;
                    }

                    .e__plns__hdg__hd {
                        @media screen and (min-width: 1024px) {
                            transform: translateX(-11px);
                        }

                        @media screen and (min-width: 1280px) {
                            transform: translateX(-14px);
                        }
                    }
                }

                &--3 {
                    background: $rgba-purple-commercial;
                    border: 1px solid rgba($rgba-purple-light5, 0.3);
                    box-shadow: 0 4px 0 rgba(17, 32, 0, 0.50), 0 0.5px 0 rgba(17, 32, 0, 0.8); // $rgba-green-dark5

                    .e__plns__dscount {
                        color: $rgba-green-dark3;
                    }

                    .e__plns__hdg__hd {
                        @media screen and (min-width: 1024px) {
                            transform: translateX(-12px);
                        }

                        @media screen and (min-width: 1280px) {
                            transform: translateX(-14px);
                        }
                    }
                }

                //teams
                &--4 {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    align-items: center;
                    justify-items: stretch;
                    min-width: 100%;
                    max-width: 570px;
                    color: white;
                    background: $rgba-purple;
                    border: 1px solid rgba($rgba-purple-light5, 0.3);
                    box-shadow: 0 4px 0 rgba(17, 32, 0, 0.50), 0 0.5px 0 rgba(17, 32, 0, 0.8);
                    overflow: hidden;

                    @media (min-width: 768px) {
                        min-width: 570px;
                    }

                    @media (min-width: 1024px) {
                        min-width: 570px;
                    }

                    .e__plns__hdg__hd,
                    .e__plns__lst__plan__info__item__txt {
                        color: white;
                    }

                    .e__plns__hdg__hd {
                        margin: 15px auto 0;
                    }

                    .e__plns__lst__plan__info__item:not(:first-child) {
                        display: flex;
                    }

                    .e__plns__lst__plan__badge {
                        position: absolute;
                        left: -48px;
                        top: 48px;
                        width: 220px;
                        padding: 2px;
                        font-weight: 900;
                        text-align: center;
                        color: $rgba-purple;
                        background-color: $rgba-green-light4;
                        transform: rotate(-45deg);
                    }

                    .e__plns__lst__plan__info {
                        height: auto;
                        max-width: 90%;
                        margin: 0;
                        padding: 20px 0 !important;

                        &__item__ico svg path {
                            fill: $rgba-green-light6;
                        }

                        @media (min-width: 768px) {
                            max-width: 70%;
                        }
                    }

                    .e__plns__lst__plan__slogan {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        max-width: 70%;
                        gap: 5px;
                        padding: 15px 0;

                        &__hd {
                            padding: 1px 10px;
                            border-radius: 6px;
                            font-weight: 900;
                            color: $rgba-purple;
                            background-color: $rgba-green-light4;
                        }
                    }

                    .e__plns__vlue {
                        max-width: 96%;

                        @media (min-width: 768px) {
                            max-width: 70%;
                        }

                        &__iva__txt,
                        &__ttl__txt {
                            display: inline-flex;
                            margin: 5px;
                        }
                    }

                    .e__plns__lst__plan__btns {
                        justify-content: center;
                        min-height: auto;
                        max-width: 96%;

                        @media (min-width: 768px) {
                            max-width: 70%;
                        }
                    }
                }

                &__hd {
                    grid-column: 1;
                    grid-row: 1;
                    align-self: center;

                    @media screen and (min-width: 1024px) {
                        grid-column: 1/3;
                        grid-row: 1;
                    }

                    .grid-profile__content & {
                        @media screen and (max-width: 1480px) {
                            grid-column: 1;
                            grid-row: 1;
                            align-self: center;
                        }
                    }
                }

                &__slct {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    grid-column: 2;
                    grid-row: 1;
                    margin: 0 0 16px auto;

                    @media screen and (min-width: 1024px) {
                        grid-column: 1/3;
                        grid-row: 2;
                        margin: 0 0 16px 0;
                    }

                    @media screen and (min-width: 1920px) {
                        height: 144px;
                        margin: 12px 0 16px 0;
                    }

                    @media screen and (max-width: 540px) {
                        margin: 0 0 4px auto;
                    }

                    .e__slct {
                        margin-bottom: 4px;

                        @media screen and (min-width: 1024px) {
                            margin-bottom: 8px;
                        }

                        &--min {
                            max-width: 190px;
                            min-width: 130px;
                            width: 100%;

                            @media screen and (min-width: 1024px) {
                                min-width: 180px;
                            }

                            @media screen and (max-width: 540px) {
                                min-width: 110px;
                            }
                        }

                        label {
                            text-align: center;
                        }

                        .grid-profile__content & {
                            margin-bottom: 4px;
                        }
                    }

                    .grid-profile__content & {
                        @media screen and (max-width: 1480px) {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            grid-column: 2;
                            grid-row: 1;
                            margin: 0 0 16px auto;
                        }
                    }
                }

                &__info {
                    grid-column: 1/3;
                    grid-row: 2;
                    width: 100%;
                    margin-bottom: 16px;

                    @media screen and (min-width: 1024px) {
                        grid-row: 3;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        height: 82px;
                        padding: 0 0 0 4px;
                    }

                    @media screen and (min-width: 1280px) {
                        margin-bottom: 20px;
                    }

                    @media screen and (min-width: 1920px) {
                        height: 104px;
                        margin: 8px 0 52px;
                    }

                    @media screen and (max-width: 540px) {
                        margin-bottom: 4px;

                        &__item {
                            display: none;
                        }

                        &__item:first-child {
                            display: block;
                        }
                    }

                    &__item {
                        display: flex;
                        align-items: baseline;
                        gap: 2px;
                        margin-bottom: 4px;

                        &__txt {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 1.1;
                            color: #6B1580;

                            @media screen and (max-width: 1919px) {
                                font-size: 15px;
                            }

                            @media screen and (max-width: 1279px) {
                                font-size: 14px;
                            }

                            @media screen and (max-width: 540px) {
                                font-size: 13px;
                            }
                        }

                        &__ico {
                            margin-right: 4px;

                            @media screen and (max-width: 540px) {
                                margin-right: 6px;
                            }

                            svg {
                                width: 14px !important;
                                height: auto;

                                @media screen and (max-width: 540px) {
                                    width: 18px !important;
                                }
                            }
                        }

                        @media screen and (max-width: 540px) {
                            &:not(:first-child) {
                                display: none;
                            }

                            &:first-child {
                                display: flex;
                            }
                        }
                    }

                    .grid-profile__content & {
                        @media screen and (max-width: 1480px) {
                            grid-column: 1/3;
                            grid-row: 2;
                            width: 100%;
                            margin-bottom: 16px;
                            font-size: 18px;
                            font-weight: 200;
                            line-height: 1.5;
                        }
                    }
                }

                .e__plns__vlue {
                    grid-column: 1/3;
                    grid-row: 3;
                    margin-bottom: 12px;

                    @media screen and (min-width: 1024px) {
                        grid-row: 4;
                        justify-content: flex-end;
                        align-items: end;
                        margin-bottom: 20px;
                        padding: 0 24px;
                    }

                    @media screen and (min-width: 1920px) {
                        margin-bottom: 40px;
                    }

                    .grid-profile__content & {
                        @media screen and (max-width: 1480px) {
                            grid-column: 1/3;
                            grid-row: 3;
                        }
                    }
                }

                &__btns {
                    grid-column: 1/3;
                    grid-row: 4;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    min-height: 40px;

                    @media screen and (min-width: 1024px) {
                        grid-row: 5;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: end;
                        flex: 1;
                        min-height: 124px;
                    }

                    @media screen and (min-width: 1920px) {
                        max-width: 280px;
                    }

                    &>* {
                        width: 50%;

                        @media screen and (min-width: 1024px) {
                            max-width: none;
                            width: 100%;
                        }

                        @media screen and (max-width: 540px) {
                            width: auto;
                        }

                        .e__btn__ico {
                            margin-right: 12px;
                        }

                        &:first-child {
                            margin-right: 8px;
                            gap: 5px;

                            @media screen and (min-width: 1024px) {
                                margin-right: 0;
                            }
                        }

                        &:last-child {
                            margin-left: 0;
                            max-width: none;
                            @media (min-width: 1024px) {
                                max-width: 260px;
                                margin: auto;
                            }
                        }
                    }

                    &>span {
                        text-align: center;
                    }

                    &>.e__txt {
                        font-weight: 700;
                        letter-spacing: 0.5px;
                        width: 100%;
                        color: #A847BF;
                    }

                    .grid-profile__content & {
                        @media screen and (max-width: 1480px) {
                            grid-column: 1/3;
                            grid-row: 4;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            min-height: 40px;

                            &>* {
                                border: 0;
                                width: 50%;

                                &:first-child {
                                    margin-right: 8px;
                                }

                                &:last-child {
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                }

                .grid-profile__content & {
                    @media screen and (max-width: 1480px) {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-auto-rows: auto;
                        width: 100%;
                        max-width: 500px;
                        border-radius: 12px;
                        padding: 8px 20px 22px;
                        margin: 0 0 12px;

                        &:last-child {
                            margin-right: auto;
                        }
                    }
                }
            }

            &__wpr {
                position: relative;
                display: flex;
                gap: 4px;
                flex-direction: column;
                width: 100%;

                @media screen and (min-width: 1024px) {
                    flex-direction: row;
                    justify-content: center;
                    gap: 12px;
                    margin: 0;
                    max-width: 1550px;
                }

                &--basic {
                    @media screen and (min-width: 1024px) and (max-width: 1280px) {
                        gap: 8px;
                    }

                    .e__plns__lst__plan {
                        @media screen and (min-width: 1024px) and (max-width: 1280px) {
                            padding: 20px 8px 24px;
                        }

                        &--0 {
                            @media screen and (min-width: 1024px) and (max-width: 1280px) {
                                padding: 20px 0 24px;

                                .e__plns__lst__plan__info {
                                    width: 240px;
                                }
                            }

                            .e__plns__hdg__hd__ico {
                                display: none;
                            }
                        }

                        &__info {
                            @media screen and (min-width: 1024px) and (max-width: 1280px) {
                                padding: 0 0 0 8px;
                            }

                            &__item {
                                &__txt {
                                    font-size: 14px;

                                    @media screen and (max-width: 540px) {
                                        font-size: 13px;
                                    }

                                    @media screen and (min-width: 1024px) and (max-width: 1280px) {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }

                    .e__plns__vlue {
                        padding: 0;

                        &__ttl {
                            &__txt {
                                flex-direction: column;
                            }
                        }
                    }
                }

                .grid-profile__content & {
                    @media screen and (max-width: 1480px) {
                        flex-direction: column;
                        margin: 0;
                        max-width: auto;
                        width: 100%;
                    }
                }
            }

            &__tbl {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                max-width: 1550px;
                margin: auto;

                @media screen and (max-width: 720px) {
                    overflow: auto;
                    max-width: 100%;
                }

                &__hd {
                    font-size: 1.25rem;
                    text-align: left;
                    margin-bottom: 12px;
                    height: 40px;

                    @media screen and (min-width: 768px) {
                        font-size: 1.5rem;
                        text-align: center;
                        padding: 40px 16px 0;
                        margin-bottom: 50px;
                    }

                    @media screen and (max-width: 719px) {
                        height: 36px;
                    }

                    &>div {
                        @media screen and (max-width: 719px) {
                            width: calc(100vw - 16px);
                            text-align: center;
                        }
                    }
                }

                &__box {
                    position: relative;
                    border-collapse: collapse;
                    font-family: 'Nunito', Arial, Helvetica, sans-serif;
                    font-size: 12px;
                    margin: 0;
                    padding: 0;
                    border-spacing: 0;
                    background-color: #FDF9FE;
                    box-shadow: inset 0 0 0 4px #F2DDF7;
                    vertical-align: middle;
                    border-radius: 16px;

                    @media screen and (min-width: 1024px) {
                        font-size: 14px;
                    }
                }

                &__plan {
                    th {
                        position: sticky;
                        top: 52px;
                        z-index: 1;

                        @media (min-width: 768px) {
                            top: 56px;
                        }

                        @media (min-width: 1024px) {
                            top: 60px;
                        }
                    }

                    &__hd {
                        padding: 32px 8px;
                        border-bottom: 2px solid #F2DDF7;
                        border-left: 2px solid #F2DDF7;
                        vertical-align: top;

                        @media screen and (min-width: 1024px) {
                            padding: 32px 12px;
                        }

                        @media screen and (max-width: 540px) {
                            padding: 8px 8px 20px;
                        }

                        &>div {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 100%;
                            height: 100%;

                            .e__btn {
                                width: 100%;
                                max-width: none;
                            }

                            .e__lnk {
                                margin-top: 16px;
                                text-align: center;
                                justify-content: center;
                                color: $rgba-black;
                            }
                        }

                        .e__plns__hdg__hd {
                            margin: 8px 0 0;
                        }

                        &__sname {
                            height: 48px;
                            font-size: 16px;
                            font-weight: 200;
                            line-height: 1.2;
                            margin: 8px 0;
                            margin-bottom: 16px;

                            @media screen and (min-width: 1024px) {
                                font-size: 18px;
                                margin-bottom: 24px;
                            }

                            &+.e__txt {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-weight: 700;
                                letter-spacing: 0.5px;
                                width: 100%;
                                color: #A847BF;
                                z-index: 0;
                                height: 40px;

                                @media screen and (min-width: 756px) {
                                    height: 48px;
                                }

                                @media screen and (min-width: 1024px) {
                                    height: 56px;
                                }
                            }

                        }

                        &--0,
                        &--1,
                        &--2,
                        &--3 {
                            box-shadow: inset 0 -1px 0 rgba($color: #112000, $alpha: 0.33);

                            //overflow: hidden;
                            &::after {
                                content: "";
                                position: absolute;
                                top: 0;
                                right: -2px;
                                width: 2px;
                                height: 100%;
                                background: #F2DDF7;
                            }
                        }

                        &--0 {
                            background-color: white;
                            border-top: 4px solid #F2DDF7;

                            .e__plns__hdg__hd__ico {
                                display: none;
                            }
                        }

                        &--1 {
                            background-color: $rgba-purple-advanced;
                        }

                        &--2 {
                            background-color: $rgba-purple-academic;
                        }

                        &--3 {
                            background-color: $rgba-purple-commercial;
                            border-right: 0;
                            border-top-right-radius: 16px;
                        }
                    }

                    &__mpty {
                        border-bottom: 2px solid #F2DDF7;
                    }
                }

                &__wpr {
                    min-width: 100%;

                    @media screen and (max-width: 720px) {
                        margin-right: 16px;
                        padding: 0 0 0 16px;
                        //overflow: auto; //mirar JS
                    }

                    @media screen and (min-width: 721px) {
                        margin-right: 0;
                        padding: 0 16px;
                    }
                }

                &__feature {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 1.2;
                    margin-right: 6px;
                    flex-wrap: wrap;

                    @media screen and (min-width: 1024px) {
                        font-size: 16px;
                    }
                }

                &__row {
                    &:nth-child(even) {
                        background: rgba($rgba-purple-light5, 0.05);
                    }

                    &:nth-child(odd) {
                        background: rgba($rgba-purple-light5, 0.12);
                    }

                    .e__plns__lst__tbl__row__feature,
                    .e__plns__lst__tbl__cell {
                        border-bottom: 0;
                    }

                    &__hd {
                        font-size: 18px;
                        font-weight: 700;
                        letter-spacing: 0;
                        text-align: left;
                        height: 64px;
                        padding: 0 12px;
                        color: #6B1580;
                        background: #FDF9FE;
                        border-left: 4px solid #F2DDF7;
                        border-right: 4px solid #F2DDF7;

                        @media screen and (max-width: 540px) {
                            font-size: 16px;
                            height: 64px;
                        }

                        @media screen and (min-width: 1024px) {
                            font-size: 24px;
                            letter-spacing: 0.5px;
                            text-align: center;
                            height: 80px;
                        }

                        &__nowrap {
                            white-space: nowrap;
                        }
                    }

                    &__feature {
                        display: inline-flex;
                        align-items: center;
                        min-width: 100%;
                        margin-right: auto;
                        padding: 18px 6px 18px 12px;
                        border-bottom: solid 2px #F2DDF7;

                        @media screen and (max-width: 360px) {
                            font-size: 12px;
                        }

                        @media screen and (min-width: 1024px) {
                            padding: 28px 6px 28px 12px;
                        }

                        &>* {
                            display: inline-flex;
                        }

                        button {
                            width: 14px;
                            min-width: 14px;
                            height: 14px;
                            margin: 0;
                            padding: 0;
                            color: #112000;
                            background: transparent;
                            border: 1px solid #112000;
                            border-radius: 50%;
                            font-size: 9px;
                        }
                    }
                }

                &__cell {
                    border-left: solid 2px white;
                    border-bottom: solid 2px #F2DDF7;
                }

                &__foot {
                    .e__plns__lst__tbl__plan__hd {
                        padding: 64px 8px 32px;
                    }
                }

                .e__ico {
                    &__x {
                        path {
                            fill: $rgba-red-light1;
                        }
                    }
                }

                .grid-profile__content & {
                    margin: 0;
                    padding: 0;

                    &__wpr {
                        margin-right: 0;
                        padding: 0 0 124px;
                        min-width: auto;
                    }
                }
            }

            &__bnn {
                display: flex;
                justify-content: center;
                background: $rgba-purple;
                margin-bottom: 64px;

                @media screen and (min-width: 1024px) {
                    margin-bottom: 96px;
                }

                &__dsk {
                    @media screen and (min-width: 541px) {
                        display: block;
                    }

                    @media screen and (max-width: 540px) {
                        display: none;
                    }
                }

                &__mob {
                    @media screen and (min-width: 541px) {
                        display: none;
                    }

                    @media screen and (max-width: 540px) {
                        display: block;
                    }
                }
            }

            .e__fq {
                width: 100%;
                padding: 40px 10px;

                @media screen and (min-width: 1920px) {
                    margin-bottom: 284px;
                }
            }
        }

        //opciones de pago
        &__opt {
            display: flex;
            flex-direction: column;
            max-width: 1280px;
            margin: 0 auto;
            padding: 0 16px;
            transition: all .2s ease-out;

            @media screen and (max-width: 540px) {
                .e__lnk {
                    font-size: 13px;
                }
            }

            &__hd {
                font-size: 13px;
                color: #112000;
                padding-bottom: 4px;
                word-break: keep-all;
                min-width: max-content;

                @media screen and (min-width: 1024px) {
                    font-size: 15px;
                }
            }

            form {
                @media screen and (min-width: 1024px) {
                    display: grid;
                    max-width: 1550px;
                    margin: 0 auto;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 32px;

                    fieldset {
                        grid-column: 1;
                    }

                    .e__plns__cta {
                        position: relative;
                        grid-column: 2;
                        grid-row: 1/3;
                    }
                }
            }

            &__plan,
            &__lcns,
            &__perid {
                justify-content: space-between;
                align-items: center;
                padding: 16px 0;
                border-bottom: 1px solid #f2ddf7;

                .e__slct select {
                    min-width: 160px;
                    font-weight: 700;
                }

                h3 {
                    font-weight: 500;
                }
            }

            &__lcns {
                display: flex;
                flex-direction: column;
                align-items: inherit;
                width: 100%;
                max-width: 260px;
                margin: 0 auto;

                @media screen and (min-width: 1024px) {
                    max-width: 360px;
                }
                .e__plns__opt__hd {
                    font-size: 13px;
                    width: 100%;
                    max-width: 260px;
                    margin: 0 auto;

                    @media screen and (min-width: 1024px) {
                        font-size: 15px;
                        max-width: 360px;
                    }
                }
            }

            &__perid {
                margin-bottom: 16px;
                border: 0;

                .e__plns__dscount {
                    justify-content: center;
                    padding-top: 6px;
                }
            }

            //contenedor grid desktop
            &__main {
                display: flex;
                flex-direction: column;
                margin-bottom: 96px;
                transition: all .2s ease-out;

                @media screen and (min-width: 1024px) {
                    flex-direction: row-reverse;
                    justify-content: center;
                    gap: 16px;
                    margin-bottom: 176px;
                    padding: 0 32px;

                    .grid-profile__content & {
                        gap: 12px;
                    }
                }

                &>.e__addrs {
                    display: flex;
                    flex-direction: column;
                    line-height: 15px;
                    padding: 12px 6px 12px 12px;

                    @media screen and (min-width: 540px) {
                        grid-column: 1/3;
                        grid-row: 1;
                        flex-direction: row;
                        align-items: flex-end;
                        padding: 24px 6px 24px 16px;
                        margin: 0 0 24px;
                    }

                    @media screen and (min-width: 10204px) {
                        line-height: 17px;
                    }

                    legend {
                        margin: 0 0 4px;

                        @media screen and (min-width: 540px) {
                            margin: 0;
                        }
                    }

                    &__wpr {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media screen and (min-width: 540px) {
                            padding: 0 8px;
                            justify-content: flex-start;
                        }
                    }

                    .e__addrs__name {
                        font-weight: 300;
                        line-height: 1.15;
                        word-break: keep-all;
                    }

                    .e__addrs__mail {
                        font-weight: 700;
                        line-height: 1.15;
                        word-break: keep-all;
                        color: #6B1580;
                        padding: 0 4px;

                        @media screen and (min-width: 540px) {
                            padding: 0 8px;
                        }
                    }
                }

                &>.e__btn__prmry {
                    order: 3;
                    max-width: 360px;
                    margin: 40px auto;
                }

                &__wpr {
                    display: block;
                    width: 100%;
                    transition: all .2s ease-in;

                    &~.e__btn__trtry {
                        max-width: none;
                    }

                    //contendor opciones plan
                    &__plan {
                        padding: 20px 10px 11px;
                        margin-bottom: 36px;
                        border-radius: 16px;
                        background: #FDF9FE;
                        box-shadow: inset 0 0 0 3px #F2DDF7;
                        transition: all .2s ease-in;

                        @media screen and (min-width: 540px) {
                            padding: 20px 16px 17px;
                        }

                        @media screen and (min-width: 1024px) {
                            margin-bottom: 28px;
                            border-radius: 20px;
                        }
                    }

                    .e__plns__link {
                        @media screen and (max-width: 540px) {
                            margin-top: -20px;
                        }
                    }

                    //comparativa planes
                    .e__plns__hdg__wpr {
                        &__lst {
                            margin: 0 0 32px;
                            max-width: calc(100% - 64px);
                        }
                    }
                }

                .grid-profile__content & {
                    justify-content: flex-end;
                    margin-bottom: 64px;
                    padding: 0;
                }
            }

            //opciones de plan
            &__box {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                border-radius: 12px;
                transform-origin: 0 0;
                transform: scale(1, 0);
                animation: topTobottom .2s ease-in-out forwards;
                transition: all .2s ease-out;

                @media screen and (min-width: 1024px) {
                    grid-column: 1;
                    grid-row: inherit;
                    border-radius: 16px;
                    margin-bottom: 40px;
                }

                &__wpr {
                    display: flex;
                    flex-direction: column;
                    padding: 12px 12px;
                    border-radius: 20px;
                    background: white;
                    box-shadow: inset 0 0 0 2px #E6C2EF;
                }
            }

            //metodo de pago
            &__pay {
                display: flex;
                flex-direction: column;
                height: fit-content;
                padding: 0;
                margin: 0;
                //box-shadow: inset 0 0 0 2px #F2DDF7;
                border-radius: 12px;
                transition: all .2s ease-out;

                @media screen and (min-width: 1024px) {
                    border-radius: 16px;
                }

                &__wpr {
                    display: flex;
                    flex-direction: column;
                    padding: 12px 12px;
                    border-radius: 20px;
                    background: white;
                    box-shadow: inset 0 0 0 1px #E6C2EF;

                    @media screen and (max-width: 540px) {
                        border-radius: 12px;
                    }

                    .e__chck {
                        height: 56px;
                        padding-left: 12px;
                        border-radius: 6px;
                        padding: 0 16px;
                        margin: 0;

                        &:hover,
                        &:focus,
                        &:active,
                        &:focus-visible {
                            box-shadow: inset 0 0 0 3px #A847BF;
                        }

                        @media screen and (min-width: 756px) {
                            height: 64px;
                            padding-left: 16px;
                        }

                        label {
                            font-weight: 700;
                        }
                    }

                    &__item {
                        box-shadow: inset 0 0 0 1px #E6C2EF;
                        border-radius: 6px;
                        margin: 0 0 2px;

                        @media screen and (min-width: 1024px) {
                            margin: 0 0 4px;
                        }

                        &__info {
                            padding: 12px 12px 20px 12px;

                            @media screen and (min-width: 540px) {
                                padding: 12px 12px 20px 12px;
                            }

                            @media screen and (min-width: 1024px) {
                                padding: 12px 16px 20px 16px;
                            }
                        }
                    }

                    &__save {
                        display: flex;
                        order: 1;

                        input {
                            width: 0px;
                            height: 0px;
                            overflow: hidden;
                            opacity: 0;
                        }

                        label,
                        &__item {
                            display: flex;
                            align-items: center;
                            flex: 1;
                            box-shadow: inset 0 0 0 1px #E6C2EF;
                            border-radius: 6px;
                            gap: 6px;
                            margin: 0 0 2px;
                            padding: 16px;
                            transition: all .2s ease;
                            cursor: pointer;

                            @media screen and (min-width: 1024px) {
                                margin: 0 0 4px;
                                padding: 20px 24px;
                            }

                            &:hover {
                                box-shadow: inset 0 0 0 2px #a847bf;
                            }
                        }

                        input:checked+label {
                            box-shadow: inset 0 0 0 3px #a847bf;
                        }

                        &__item {
                            padding: 16px 8px 16px 16px;
                            box-shadow: inset 0 0 0 2px $rgba-red-light2;

                            @media screen and (min-width: 1024px) {
                                padding: 20px 12px 20px 24px;
                            }
                        }

                        &__info {
                            display: flex;
                            align-items: flex-end;
                            gap: 6px;
                            line-height: 1;

                            &__wpr {
                                display: flex;
                                flex-direction: column;
                            }

                            &__hd,
                            &__date {
                                font-size: 11px;
                                font-weight: 300;
                                margin-bottom: 4px;

                                @media screen and (min-width: 1024px) {
                                    font-size: 13px;
                                    margin-bottom: 6px;
                                }
                            }

                            &__date {
                                margin: 0;
                            }

                            &__db {
                                font-size: 14px;
                                font-weight: 700;
                                color: #6B1580;
                                padding: 0 0 0 2px;

                                @media screen and (min-width: 1024px) {
                                    font-size: 16px;
                                    padding: 0 0 0 2px;
                                }
                            }
                        }

                        &__ico {
                            margin-right: 4px;

                            @media screen and (min-width: 540px) {
                                margin-right: 12px;
                            }

                            @media screen and (min-width: 1024px) {
                                margin-right: 20px;
                            }

                            svg {
                                width: 26px;
                                height: auto;

                                @media screen and (min-width: 541px) {
                                    width: 32px;
                                }
                            }
                        }

                        &__nmbr {
                            font-size: 14px;
                            font-weight: 700;
                            color: #6B1580;
                            padding: 4px 0 0 2px;

                            @media screen and (min-width: 1024px) {
                                font-size: 16px;
                                padding: 8px 0 0 2px;
                            }
                        }

                        & label>.e__ico,
                        &__item>.e__ico {
                            margin-left: auto;

                            svg {
                                width: 28px;
                                height: auto;

                                @media screen and (min-width: 1024px) {
                                    width: 36px;
                                }
                            }
                        }

                        &--rrr {
                            order: 0;
                        }

                        &:focus~.e__plns__opt__pay__wpr__save--rrr .e__plns__opt__pay__wpr__save__item,
                        &:hover~.e__plns__opt__pay__wpr__save--rrr .e__plns__opt__pay__wpr__save__item {
                            box-shadow: inset 0 0 0 1px #E6C2EF;
                        }
                    }

                    &__save~.e__btn__trtry {
                        margin: 12px auto 8px;
                        min-width: 260px;
                        order: 2;
                    }

                    &>.e__lnk {
                        padding: 12px 2px;

                        @media screen and (min-width: 1024px) {
                            padding: 16px 2px;
                        }

                        @media screen and (max-width: 540px) {
                            padding: 16px 0;
                            margin: 0 auto;
                        }
                    }
                }

                &__info {
                    display: flex;
                    align-items: center;
                    padding: 16px;
                    border-radius: 6px;
                    line-height: 1;
                    gap: 6px;

                    //box-shadow: inset 0 0 0 1px #E6C2EF;
                    @media screen and (min-width: 1024px) {
                        padding: 20px 16px;
                    }

                    @media screen and (max-width: 540px) {
                        padding: 8px 0;
                    }

                    &__wpr {
                        display: flex;
                        flex-direction: column;
                    }

                    &__wrapper{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-left: auto;
                        @media screen and (min-width: 1024px) {
                            gap: 15px;
                        }
                    }

                    &__hd,
                    &__date {
                        font-size: 11px;
                        font-weight: 300;

                        @media screen and (min-width: 1024px) {
                            font-size: 13px;
                        }
                    }

                    &__date {
                        align-self: flex-end;
                        padding-bottom: 1px;

                        @media screen and (max-width: 540px) {
                            font-size: 10px;
                        }
                    }

                    &__nmbr {
                        font-size: 14px;
                        font-weight: 700;
                        color: #6B1580;
                        padding: 4px 0 0 2px;

                        @media screen and (min-width: 1024px) {
                            font-size: 16px;
                            padding: 8px 0 0 2px;
                        }

                        @media screen and (max-width: 540px) {
                            font-size: 12px;
                        }
                    }

                    .e__lnk {
                        display: flex;
                        margin-left: auto;
                        font-size: 13px;

                        @media screen and (min-width: 1024px) {
                            font-size: 15px;
                        }
                    }
                }
            }

            @media screen and (min-width: 1024px) {

                &__box,
                &__pay,
                .e__plns__fact {
                    padding: 0 16%;
                }

            }

            @media screen and (min-width: 1280px) {

                &__box,
                &__pay,
                .e__plns__fact {
                    padding: 0 20%;
                }

            }

            @media screen and (min-width: 1920px) {

                &__box,
                &__pay,
                .e__plns__fact {
                    padding: 0 25%;
                }

            }

            .grid-profile__content & {
                padding: 0;
                margin: 0;
                max-width: none;
            }
        }

        //final tras pago
        &__end {

            //orden formulario
            form {
                display: flex;
                flex-direction: column;

                .e__plns__hdg {
                    order: 0;
                }

                .e__plns__opt__wpr {
                    order: 1
                }

                .e__plns__fact {
                    order: 3;
                }

                .e__addrs {
                    order: 2;
                }
            }

            .e__plns__fact.act:not(.mty)~.e__addrs {
                display: flex;
            }

            &__wpr {
                display: flex;
                flex-direction: column;
                padding: 8px 16px;
                margin-bottom: 32px;
            }

            .e__plns__opt__pay {
                &.act {
                    .e__plns__opt__pay__wpr {
                        display: flex;
                    }

                    .e__txt__info {
                        display: block;
                    }
                }
            }
        }

        //cta
        &__cta {
            position: fixed;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            width: 100%;
            padding: 18px 32px 12px;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
            background: white;
            box-shadow: 0 -4px 0 rgba($color: #000000, $alpha: 0.23);
            transition: all .2s ease-out;
            transform: translateY(120%);
            animation: bottomToTop 0.2s ease-in 0.3s forwards;
            z-index: 100;

            @media screen and (min-width: 1024px) {
                position: sticky;
                top: 80px;
                bottom: auto;
                left: auto;
                height: fit-content;
                width: 50%;
                border-radius: 16px;
                box-shadow: inset 0 0 0 2px #F2DDF7,
                    0 4px 0 rgba($color: #000000, $alpha: 0.23);
                transform-origin: 0 0;
                transform: scaleX(0);
                animation: scaleXAni 0.1s ease-in 0.3s forwards;
            }

            @media screen and (max-width: 540px) {
                padding: 16px 20px 12px;
            }

            &--0 {
                background: white;
                box-shadow: 0 4px 0 rgba(17, 32, 0, 0),
                    0 0.5px 0 rgba(17, 32, 0, 0); // $rgba-green-dark5

                @media screen and (min-width: 1024px) {
                    border: 2px solid rgba(168, 71, 191, 0.1);
                }
            }

            &--1 {
                background: $rgba-purple-academic;
                box-shadow: 0 -5px 0 rgba(17, 32, 0, 0.10),
                    0 -0.5px 0 rgba(17, 32, 0, 0.35); // $rgba-green-dark5

                @media screen and (min-width: 1024px) {
                    border: 2px solid #F2DDF7;
                    box-shadow: 0 6px 0 rgba(17, 32, 0, 0.10),
                        0 0.5px 0 rgba(17, 32, 0, 0.35); // $rgba-green-dark5
                }
            }

            &--2 {
                background: $rgba-purple-academic;
                box-shadow: 0 -5px 0 rgba(17, 32, 0, 0.15),
                    0 -0.5px 0 rgba(17, 32, 0, 0.4); // $rgba-green-dark5

                @media screen and (min-width: 1024px) {
                    border: 2px solid #DEADEA;
                    box-shadow: 0 6px 0 rgba(17, 32, 0, 0.15),
                        0 0.5px 0 rgba(17, 32, 0, 0.4); // $rgba-green-dark5
                }
            }

            &--3 {
                background: $rgba-purple-commercial;
                border: none;
                box-shadow: 0 -5px 0 rgba(17, 32, 0, 0.20),
                    0 -0.5px 0 rgba(17, 32, 0, 0.45); // $rgba-green-dark5

                @media screen and (min-width: 1024px) {
                    border: 2px solid rgba(168, 71, 191, 0.3);
                    box-shadow: 0 6px 0 rgba(17, 32, 0, 0.20),
                        0 0.5px 0 rgba(17, 32, 0, 0.45); // $rgba-green-dark5
                }

                // .e__plns__dscount {
                //     color: $rgba-green-dark1;
                // }
                // & > .e__form .e__lnk {
                //     color: $rgba-green-light6;
                // }
            }

            .e__plns__hdg__hd {
                margin: 0 auto 12px;

                @media screen and (max-width: 540px) {
                    margin: 0 auto 4px;
                }

                @media screen and (min-width: 541px) and (max-width: 1024px) {
                    width: auto;
                    margin: 0 20%;
                }
            }

            .e__plns__vlue {

                &__iva,
                &__end,
                &__ttl {
                    margin: 8px 0;

                    @media screen and (max-width: 540px) {
                        margin: 2px 0;
                    }
                }

                @media screen and (max-width: 540px) {
                    margin: 0;
                    margin-bottom: 8px;
                }

                @media screen and (min-width: 541px) and (max-width: 1024px) {
                    width: auto;
                    margin: 0 20%;
                }
            }

            &>.e__form {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 4px;
                padding: 16px 4px 16px 12px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                background: rgba(255, 255, 255, 0.2);
                border-radius: 8px;
                font-size: 11px;
                margin-bottom: 6px;

                @media screen and (min-width: 540px) and (max-width: 1024px) {
                    width: auto;
                    margin: 0 20%;
                }

                @media screen and (min-width: 1024px) {
                    font-size: 14px;
                    gap: 6px;
                    padding: 20px 8px 20px 12px;
                }

                input {
                    margin-right: 4px;
                    cursor: pointer;
                    width: 16px;
                }

                @media screen and (max-width: 540px) {
                    margin: 0;
                }
            }

            &__btn {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 12px 0;

                @media screen and (min-width: 1024px) {
                    margin-top: 24px;
                }

                .e-btn {
                    min-width: 260px;
                    gap: 5px;
                    svg {
                        transition: .2s ease-out;
                        path, circle {
                            transition: .2s ease-out;
                        }
                    }
                    &:hover, &:focus, &:focus-within  {
                        svg {
                            transition: .2s ease-in;
                            path {
                                stroke: white;
                                transition: .2s ease-in;
                            }
                            circle {
                                fill: white;
                                transition: .2s ease-in;
                            }  
                        }
                    }
                }
            }

            .e__addrs~& {
                @media screen and (min-width: 1024px) {
                    grid-row: 3/6;
                }
            }

            //con CTA desplegado en Desktop
            &~.e__plns__opt__main__wpr {
                @media screen and (min-width: 1024px) {
                    width: 50%;
                    transition: all .2s ease-out;

                    .grid-profile__content & {
                        width: 60%;
                    }
                }

                .e__plns__opt__box,
                .e__plns__opt__pay,
                .e__plns__fact {
                    padding: 0;
                }

                .e__plns__pdf {
                    padding: 0;
                }

                .e__plns__link {
                    margin: 0;
                }
            }

            .grid-profile__content & {
                @media screen and (min-width: 1024px) {
                    top: 96px;
                    width: 40%;
                }

                .e__plns__vlue__end__txt,
                .e__plns__vlue__iva__txt,
                .e__plns__vlue__ttl__txt {
                    @media screen and (min-width: 1024px) and (max-width: 1400px) {
                        font-size: 12px;
                    }
                }

                .e__plns__hdg__hd {
                    @media screen and (min-width: 1024px) {
                        align-items: flex-start;

                        .e__plns__hdg__hd__ico {
                            margin-bottom: 0;
                            margin-top: 9px;
                        }

                        .e__plns__hdg__hd__wrp {
                            display: grid;
                            grid-template-columns: auto auto;

                            .e__plns__hdg__hd__hdg {
                                grid-column: 1 / 3;
                            }

                            .e__plns__hdg__hd__lcns {
                                grid-column: 1;
                            }

                            .e__plns__hdg__hd__perid {
                                grid-column: 2;
                                padding-left: 4px;
                            }
                        }
                    }
                }

                .e__plns__hdg__hd__wrp {
                    @media screen and (min-width: 1024px) {
                        display: grid;
                        grid-template-columns: auto auto;

                        .e__plns__hdg__hd__hdg {
                            grid-column: 1 / 3;
                        }

                        .e__plns__hdg__hd__lcns {
                            grid-column: 1;
                        }

                        .e__plns__hdg__hd__perid {
                            grid-column: 2;
                        }
                    }
                }
            }
        }
    }
}

//payforme input
.e__textformular {

    //reset color
    &.success .e__textformular__input input:not(:placeholder-shown) {
        background-color: #F2DDF7 !important;
        color: #6b1580 !important;
        font-weight: 700 !important;
    }

    input {
        width: 100%;
        max-width: 100% !important;
    }

    &__icons {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
    }

    .e-btn {
        .e__btn__ico {
            margin: 0;
        }
    }
}

//boton copy pay for me
.e__plns__link .e-btn--mini-square {
    &:hover svg path {
        stroke: white;
    }
}


//animaciones
@keyframes topTobottom {
    from {
        transform: scale(1, 0);
    }

    to {
        transform: scale(1, 1);
    }
}

@keyframes bottomToTop {
    from {
        transform: translateY(120%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes scaleXAni {
    from {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
}