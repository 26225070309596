
.cards {
    position: absolute;
    right: 6px;
    top: 26.5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2px;
    @media screen and (min-width: 768px) {
        right: 11px;
        top: 32.5px;
        gap: 3px;
    }
    @media screen and (min-width: 1024px) {
        top: 37.5px;
        gap: 5px;
    }
    svg {
        height: inherit;
        @media screen and (min-width: 768px) {
            width: 32px;
        }
    }
    & > div {
        z-index: 2;
        pointer-events: none;
    }

    &[data-failed="true"]{
        right: 34px;
        @media screen and (min-width: 768px) {
            right: 44px;
        }
    }

    select {
        position: absolute;
        right: -4px;
        top: 1px;
        height: 23px;
        width: 45px;
        max-width: 45px;
        margin: 0;
        padding: 0 16px;
        outline: 0;
        border: 1px solid #ccc;
        border-radius: 4px;
        z-index: 1;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC42NDgyNDkgMC42MTY5OTlDMC44OTkzMDEgMC4zNjU5NDggMS4zMDYzNCAwLjM2NTk0OCAxLjU1NzM5IDAuNjE2OTk5TDQuOTU5OTYgNC4wMTk1N0w4LjM2MjU0IDAuNjE2OTk5QzguNjEzNTkgMC4zNjU5NDggOS4wMjA2MiAwLjM2NTk0OCA5LjI3MTY3IDAuNjE2OTk5QzkuNTIyNzIgMC44NjgwNTEgOS41MjI3MiAxLjI3NTA5IDkuMjcxNjcgMS41MjYxNEw1LjQxNDUzIDUuMzgzMjhDNS4xNjM0OCA1LjYzNDMzIDQuNzU2NDQgNS42MzQzMyA0LjUwNTM5IDUuMzgzMjhMMC42NDgyNDkgMS41MjYxNEMwLjM5NzE5OCAxLjI3NTA5IDAuMzk3MTk4IDAuODY4MDUxIDAuNjQ4MjQ5IDAuNjE2OTk5WiIgZmlsbD0iIzExMjAwMCIvPgo8L3N2Zz4K");
        background-repeat: no-repeat;
        background-position: -20px 3px;
        transition: all .2s ease;
        @media screen and (min-width: 768px) {
            right: -5px;
            top: -3px;
            height: 32px;
            width: 64px;
            max-width: 64px;
            background-position: -25px 7px;
            padding: 0 26px;
        }
        &:hover, &:focus {
            border: 1px solid #112000;
            transition: all .2s ease;
        }
    }
}